import React from "react";

import topNewsie from "../../../../../../../Assets/top-newsie.png";

import "./UserStat.css";

const UserStat = (props) => {
  const { label, icon, value, hidden, isPng } = props;

  const className = `stat-container ${hidden ? "hidden" : ""}`;
  return (
    <div className={className}>
      {hidden && <div className="stat-container-blur"></div>}
      <div className="stat-label">{label}</div>
      <div className="stat-badge-icon-container">
        {isPng && <img src={topNewsie} />}
        {!isPng && icon}
      </div>
      <div className="stat-value">{value}</div>
    </div>
  );
};

export default UserStat;

import React from "react";


import './SuccessCheckEmail.css';

const SuccessCheckEmail = () => {
  return (
    <div className="subscription-confirmation-container">
      <div>
        <h2>Thank you for subscribing!</h2>
      </div>
      <div style={{ fontSize: "300%" }}>
        <h1>🥳</h1>
      </div>
      <div>
        <h2>Check your inbox!</h2>
        <h3>Your first Newsie is on the way</h3>
      </div>
    </div>
  );
};

export default SuccessCheckEmail;

import React, { useMemo, useState, useEffect, useRef } from "react";
import { CONFIG } from "../../util/constants";
import logo from "../../Assets/newsie-triumphant-192-removebg.png"; // Tell webpack this JS file uses this image
// import logo from "../../Assets/royal-newsie-215.png"; // Tell webpack this JS file uses this image

import SuccessCheckEmail from "./pages/SuccessCheckEmail";

import { isValidEmail } from "../../util/util";
import "./EmailCollection.css";
import CollectEmail from "./pages/CollectEmail";
import Confetti from "react-confetti";

const { SERVER_API } = CONFIG;

const EmailCollection = (props) => {
  const { handleClickLoginButton } = props;
  const className = `email-collection-page-container`;
  const [email, setEmail] = useState();
  const isEmailValid = useMemo(() => isValidEmail(email), [email]);

  const [subscribeSuccess, setSubscribeSuccess] = useState(false);

  const handleEmailInputChanged = (e) => {
    setEmail(e.target.value);
  };

  const submitEmail = async () => {
    const refSource = localStorage.getItem("refSource");
    const referralCode = localStorage.getItem("referralCode");


    const response = await fetch(`${SERVER_API}/subscribe`, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify({ email, refSource, referralCode }),
    });

    const { status } = response;
    setSubscribeSuccess(true);
  };

  const handleSubmitEmaiClicked = (e) => {
    submitEmail(email);
  };

  const [height, setHeight] = useState(0);
  const [width, setWidth] = useState(0);
  const [left, setLeft] = useState(0);

  const ref = useRef(null);
  const pageContainerRef = useRef(null);

  useEffect(() => {
    if (ref.current) {
      setHeight(ref.current.clientHeight);
      setWidth(ref.current.clientWidth);
    }
    if (pageContainerRef.current) {
      setLeft(pageContainerRef.current.getBoundingClientRect().left);
    }
  }, [ref.current, pageContainerRef.current]);

  return (
    <div ref={pageContainerRef} className={className}>
      <div ref={ref} className="landing-page-newsie-icon">
        <div className="confetti-container" style={{ left }}>
          <Confetti
            numberOfPieces={50}
            width={width}
            height={height}
            opacity={0.3}
          />
        </div>

        <div>
          <div className="newsie-logo-container-email-collection">
            <img src={logo} width={120} height={120} alt="Logo" />
          </div>
        </div>
        <div className="email-collection-title-container">
          <h1>Meet NEWSIE!</h1>
          <p>Daily Newsletter & News Quiz</p>
        </div>
      </div>
      <div className="subscribe-motivator-container">
        <div className="explain-the-concept-container">
          {subscribeSuccess && <SuccessCheckEmail />}

          {!subscribeSuccess && (
            <CollectEmail
              email={email}
              handleEmailInputChanged={handleEmailInputChanged}
              handleSubmitEmaiClicked={handleSubmitEmaiClicked}
              isEmailValid={isEmailValid}
              handleClickLoginButton={handleClickLoginButton}
            />
          )}
        </div>
      </div>
      <div className="terms-footer">
        <div className="terms-link">
          <a href="/terms">Terms</a>
        </div>
        <div className="terms-link">
          <a href="/privacy"> Privacy</a>
        </div>
        <div className='terms-link'>© newsie-fyi, LLC</div>
      </div>
    </div>
  );
};

export default EmailCollection;

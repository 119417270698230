import React from "react";


import "./Achievement.css";
import {
  COMPLETED_FIRST_NEWSIE,
  FIVE_DAY_STREAK,
  TEN_DAY_STREAK,
  TWO_DAY_STREAK,
  WON_FIRST_NEWSIE,
} from "../../../../../../../util/constants";

import wonFirstNewsieAward from "../../../../../../../Assets/Awards/award-won-first-newsie-removebg.png"; // Tell webpack this JS file uses this image
import newsiePaper from "../../../../../../../Assets/Awards/newsie-paper-removebg.png";

// Streaks
import tenDayStreak from "../../../../../../../Assets/Awards/ten-day-streak-removebg.png";
import twoDayStreak from "../../../../../../../Assets/Awards/two-day-streak-removebg.png";
import fiveDayStreak from "../../../../../../../Assets/Awards/five-day-streak.png";


const ACHIEVEMENT_TO_ASSET_MAP = {
  [COMPLETED_FIRST_NEWSIE]: newsiePaper,
  [WON_FIRST_NEWSIE]: wonFirstNewsieAward,
  [TWO_DAY_STREAK]: twoDayStreak,
  [TEN_DAY_STREAK]: tenDayStreak,
  [FIVE_DAY_STREAK]: fiveDayStreak
};

const Achievement = (props) => {
  const { title, id } = props;
  const achievementBadgeLabelContainerClass = `achievement-badge-label-container ${
    title.length > 20 ? "long" : ""
  }`;
  return (
    <div className="achievement-badge-container">
      <div className="achievement-badge-container-image">
        <img src={ACHIEVEMENT_TO_ASSET_MAP[id]} alt="" />
      </div>
        <div className={achievementBadgeLabelContainerClass}>{title}</div>
    </div>
  );
};

export default Achievement;

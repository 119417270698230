import React from "react";

import {useDispatch} from 'react-redux';
import { setModal } from "../../../../redux/features/modalSlice";
import { MODALS } from "../../../Modal";

import "./CollectEmail.css";
const CollectEmail = (props) => {
  const {
    email,
    handleEmailInputChanged,
    handleSubmitEmaiClicked,
    isEmailValid,
    handleClickLoginButton,
  } = props;
  const dispatch = useDispatch();

  const handleClickGameInfoLink = () => {
    dispatch(setModal(MODALS.HOW_TO_PLAY));
  }

  return (
    <>
      <div className="title-container">
        <h1>Read the Newsletter 🗞️</h1>
        <p>
         A brief daily skim of current events
        </p>
        <h1>
          <u>Remember</u> the News 🧠
        </h1>
        <p>
          Click the link at the bottom of each email to play the daily <span onClick={handleClickGameInfoLink}> <strong><i>news quiz game</i></strong></span>
        </p>
      </div>
      {/* <div className="email-collection-divider-container"> */}
        <div className="title-description-divider" />
      {/* </div> */}
      <div className="email-inputs-container">
        <div className="email-input-label">
          <h1>Give us your email!</h1>
          <h2>We'll make you smart & funny!</h2>
        </div>
        <div className="email-input-container">
          <input
            className='email-input'
            type="email"
            placeholder="smart-and-funny@gmail.com"
            value={email}
            onChange={handleEmailInputChanged}
            autoComplete="email"
          />
          <button onClick={handleSubmitEmaiClicked} disabled={!isEmailValid}>
            Subscribe
          </button>
        </div>
      </div>

      <div className="login-button-container">
        <h3>
          <i>Already have an account?</i>
        </h3>
        <div className="landing-page-login-button-container">
          <div
            className="landing-page-login-button"
            onClick={(e) => {
              e.stopPropagation();
              handleClickLoginButton();
            }}
          >
            Login
          </div>
        </div>
      </div>
    </>
  );
};

export default CollectEmail;

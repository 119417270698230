import React, { useState, useMemo, useEffect } from "react";

import { useDispatch, useSelector } from "react-redux";
import { DEFAULT, LOADING_SCREEN, CHECK_YOUR_EMAIL, CAPTCHA } from "../..";

import {
  setLoginModalScreen,
  setAuthPassword,
  loginWithEmailStart,
  setCaptchaAction,
} from "../../../../../../redux/features/authSlice";
import { isValidPassword } from "../../../../../../util/util";
import { CAPTCHA_ACTIONS } from "../Captcha";

import "./LoginWithEmail.css";

const LoginWithEmail = (props) => {
  const { invalidPassword } = props;
  const dispatch = useDispatch();
  const [showErrorMessage, setShowErrorMessage] = useState(false);

  const [password, setPassword] = useState("");
  const passwordValid = useMemo(
    () => isValidPassword(password),
    [password, isValidPassword]
  );

  useEffect(() => {
    if (invalidPassword) {
      setShowErrorMessage(true);
      setTimeout(() => {
        setShowErrorMessage(false)
      }, 4000)
    }
  }, [invalidPassword])
  const authEmail = useSelector((state) => state.authReducer.authEmail);

  const handleLoginButtonClick = () => {
    dispatch(setAuthPassword(password));
    dispatch(setLoginModalScreen(LOADING_SCREEN));
    dispatch(loginWithEmailStart());
  };

  const handlePasswordChange = (e) => {
    setPassword(e.target.value);
  };

  const handleClickChangeEmailButton = () => {
    dispatch(setLoginModalScreen(DEFAULT));
  };

  const handleForgotPasswordClick = () => {
    dispatch(setCaptchaAction(CAPTCHA_ACTIONS.EMAIL_FORGOT_PASSWORD));
    dispatch(setLoginModalScreen(CAPTCHA));
  };

  return (
    <div className="login-with-email-content">
      <div>
        <h2>Enter Password to Login</h2>
      </div>
      {showErrorMessage && (<div style={{ margin: "0px", color: "red" }}>
        <h4 style={{ margin: "0px", color: "red" }}>
          Oops! Try a diff password or reset it!
        </h4>
      </div>)}
      <div className="login-buttons-container">
        <fieldset className="email-fieldset" type="email">
          <label className="email-input-form-label" htmlFor="email">
            Email
          </label>
          <div className="editable-disabled-email">
            <input
              className="email-input"
              id="email"
              disabled
              value={authEmail}
              type="email"
            ></input>
            <button
              className="edit-email-button"
              onClick={handleClickChangeEmailButton}
            >
              X
            </button>
          </div>
        </fieldset>
        <fieldset className="email-fieldset" type="password">
          <label className="email-input-form-label" htmlFor="password">
            Password
          </label>
          <input
            className="email-input"
            id="password"
            type="password"
            value={password}
            onChange={handlePasswordChange}
            autoComplete="password"
          />
        </fieldset>
        <button
          className="login-button"
          disabled={!passwordValid}
          onClick={handleLoginButtonClick}
        >
          <b>Continue</b>
        </button>
        <div
          className="forget-your-password"
          onClick={handleForgotPasswordClick}
        >
          Forget your password?
        </div>
      </div>
    </div>
  );
};

export default LoginWithEmail;

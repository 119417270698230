import React, { useEffect } from 'react';

import { useDispatch } from 'react-redux';

import { dismissAd } from '../../redux/features/modalSlice';

import './AdSenseAd.css'
const AdSenseAd = () => {
  const dispatch = useDispatch();
  useEffect(() => {
    // eslint-disable-next-line
    (adsbygoogle = window.adsbygoogle || []).push({});
  }, []);

  const handleDismissAd = () => {
    dispatch(dismissAd())
  }

  return (<div className='ad-sense-ad-container'>
    <div className='ad'>
      <ins className='adsbygoogle'
        style={{ display: 'inline-block', width: '300px', height: '500px', marginTop: '80px'}}
        data-ad-client="ca-pub-5457332723780791"
        data-ad-slot="3085229343"></ins>
      <div onClick={handleDismissAd}>
        <b>Continue to NEWSIE {`>`}</b>
      </div>
    </div>
  </div>
  );
};

export default AdSenseAd;
import React, {useMemo, useState} from 'react';
import {useParams, useNavigate} from 'react-router-dom'
import { isValidPassword } from '../../util/util';
import { resetPassword } from '../Modal/modals/Login/api';

import './ResetPassword.css';

const ResetPassword = () => {
  const { accountRecoveryToken } = useParams();
  const navigate = useNavigate();
  const [password, setPassword] = useState(); 
  const [verifyPassword, setVerifyPassword] = useState(); 
  const fistPasswordValid = useMemo(()=> isValidPassword(password),[password, isValidPassword]);
  const verifyPasswordMatches = useMemo(() => password === verifyPassword, [password, verifyPassword])
  const canSubmitResetPasswordRequest = useMemo(() => fistPasswordValid && verifyPasswordMatches, [fistPasswordValid, verifyPasswordMatches])

  const handlePasswordChange = (e) => {
    setPassword(e.target.value)
  };

  const handleVerifyPasswordChange = (e) => {
    setVerifyPassword(e.target.value)
  };

  const resetPasswordRequest = async () => {
    await resetPassword(password, accountRecoveryToken);
    // Navigate back to App
    navigate('/');
  };
  const handleResetPassword = () => {
    resetPasswordRequest();
  }
  return (<div className='reset-password-container'>
    <fieldset className='email-fieldset' type='password'>
        <label className='email-input-label' htmlFor='password'>Password</label>
        <input className='email-input' id='password' type='password' value={password} onChange={handlePasswordChange} />
      </fieldset>
      <fieldset className='email-fieldset' type='password'>
        <label className='email-input-label' htmlFor='verify-password'>Verify Password</label>
        <input className='email-input' id='verify-password' type='password' value={verifyPassword} onChange={handleVerifyPasswordChange} />
      </fieldset>

      <button className='login-button' disabled={!canSubmitResetPasswordRequest} onClick={handleResetPassword}>
        <b>Reset Password</b>
      </button>
  </div>)
} 

export default ResetPassword;
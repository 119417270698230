import React, { useMemo } from "react";

import { useSelector } from "react-redux";
import { ACHIEVEMENT_ASSETS } from "../../../../../util/constants";
import Achievement from "../../HamburgerMenu/pages/Profile/Achievement";
import UserStat from "../../HamburgerMenu/pages/Profile/UserStat";
import LoadingScreen from "../../Login/pages/LoadingScreen";

import "./PerformanceUpdate.css";

const PerformanceUpdate = (props) => {
  const selectedNewsie = useSelector(
    (state) => state.gameReducer.selectedNewsie
  );
  const recordedGameStatus = useSelector(
    (state) => state.gameReducer.recordedGameStatus[selectedNewsie]
  );

  const currentStreakRedux = useSelector(
    (state) => state.userPerformanceReducer.currentStreak
  );
  const longestStreakRedux = useSelector(
    (state) => state.userPerformanceReducer.longestStreak
  );

  // These selectors for curr streak/longeststreak are fucking stupid just update the values and use them
  const { newAwards, currentStreak, longestStreak } = useSelector(
    (state) => state.gameReducer.performanceUpdate
  );


  const STATS = useMemo(
    () => [
      {
        label: "Current Streak",
        value: currentStreak || currentStreakRedux,
        icon: "📈",
      },
      {
        label: "Longest Streak",
        value: longestStreak || longestStreakRedux,
        icon: "🙌",
      },
    ],
    [currentStreak, longestStreak, longestStreakRedux, currentStreakRedux]
  );

  const hydratedNewAchievements = useMemo(
    () => (newAwards ? newAwards.map((n) => ACHIEVEMENT_ASSETS[n]) : []),
    [newAwards]
  );
  return (
    <div className="performance-update-container">
      {recordedGameStatus ? ( // Todo switch this to recordedGameStats?
        <div className="performance-update">
          {hydratedNewAchievements.length ? (
            <>
              <div><b>New Achievement Unlocked:</b> </div>
              <div className="new-achievement-row">
                {hydratedNewAchievements.map((a, i) => (
                  <Achievement key={a.id} title={a.title} id={a.id} />
                ))}
              </div>
            </>
          ): null}
          <div className="streaks-row-container">
            <div className="streaks-row">
              {STATS.map((s, i) => (
                <div>
                  <h4>
                    {s.icon} {s.label}: {s.value}
                  </h4>
                </div>
              ))}
            </div>
          </div>
        </div>
      ) : (
        <LoadingScreen />
      )}
    </div>
  );
};

export default PerformanceUpdate;

import React, { useState, useMemo, useCallback } from "react";

import { useDispatch, useSelector } from "react-redux";
import { DEFAULT } from "../..";

import { useNavigate } from "react-router-dom";

import { createAccountWithEmail } from "../../api";
import {
  authSuccess,
  setLoginModalScreen,
} from "../../../../../../redux/features/authSlice";
import { dismissModal } from "../../../../../../redux/features/modalSlice";

import { isValidPassword } from "../../../../../../util/util";

import "./CreateAccount.css";

const CreateAccountWithEmail = (props) => {
  const { emailAccountCreationToken } = props;
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const [password, setPassword] = useState("");
  const browserId = useSelector((state) => state.authReducer.browserId);
  const userPerformance = useSelector((state) => state.userPerformanceReducer);
  const [confirmPassword, setConfirmPassword] = useState();
  
  const fistPasswordValid = useMemo(
    () => isValidPassword(password),
    [password, isValidPassword]
  );

  const verifyPasswordMatches = useMemo(
    () => password === confirmPassword,
    [password, confirmPassword]
  );

  const canSubmitResetPasswordRequest = useMemo(
    () => fistPasswordValid && verifyPasswordMatches,
    [fistPasswordValid, verifyPasswordMatches]
  );

  const emailAccountCreationBusinessLogic = useCallback(async () => {
    const emailAccountCreationResult = await createAccountWithEmail(
      emailAccountCreationToken,
      password,
      browserId,
      userPerformance
    );
    const { apiToken } = emailAccountCreationResult;
    // Todo: use Login methhod as prop?
    dispatch(authSuccess(apiToken));
    dispatch(dismissModal());
    setTimeout(() => navigate("/"), 1000);
  }, [emailAccountCreationToken, password, browserId, userPerformance]);

  const handleClickCreateAccount = () => {
    emailAccountCreationBusinessLogic();
  };

  const handlePasswordChange = (e) => {
    setPassword(e.target.value);
  };

  const handleConfirmPasswordChange = (e) => {
    setConfirmPassword(e.target.value);
  };

  return (
    <div className="login-with-email-content">
      <div className='create-account-title-container'>
        <h4>Create Account</h4>
      </div>
      <div className="login-buttons-container">
        <fieldset className="email-fieldset" type="password">
          <label className="email-input-label" htmlFor="password">
            Password
          </label>
          <input
            className="email-input"
            id="password"
            type="password"
            value={password}
            onChange={handlePasswordChange}
          />
        </fieldset>
        <fieldset className="email-fieldset" type="password">
          <label className="email-input-label" htmlFor="confirm-password">
            Confirm password
          </label>
          <input
            className="email-input"
            id="confirm-password"
            type="password"
            value={confirmPassword}
            onChange={handleConfirmPasswordChange}
          />
        </fieldset>
        <div className="password-requirements">
          <b>Password must:</b>
          <ul>
            <li>have at least one digit.</li>
            <li>contain at least one lowercase letter.</li>
            <li>contain at least one uppercase letter.</li>
            <li>be at least 8 characters in length.</li>
          </ul>
        </div>
        <button
          className="login-button"
          disabled={!canSubmitResetPasswordRequest}
          onClick={handleClickCreateAccount}
        >
          <b>Create</b>
        </button>
      </div>
    </div>
  );
};

export default CreateAccountWithEmail;

export class InvalidUserInputError extends Error {
  constructor(message) {
    super(message);
    this.name = "InvalidUserInputError";
  }
}

export class ServerError extends Error {
  constructor(message) {
    super(message);
    this.name = "ServerError";
  }
}

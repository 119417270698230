import React, {
  useCallback,
  useEffect,
  useState,
  useRef,
  useMemo,
} from "react";
import { useSelector, useDispatch } from "react-redux";
import "./App.css";
import LandingPageContainer from "./Components/LandingPage/LandingPageContainer";

import EmailCollection from "./Components/EmailCollection";
import { DateTime } from "luxon";
import AppGameContainer from "./Components/AppGameContainer";
import {
  setResultModalShown,
  setStories,
  guessStory,
  selectWord,
  setAllNewsies,
  setLastNewsieUpdateDate,
  completedClearingGame,
  getClue,
  logoutClearGame,
  recordGameStatusSuccess,
  setFeedbackModalContent,
} from "./redux/features/gameSlice";
import {
  deleteAccountError,
  deleteAccountSuccess,
  startGame,
  deleteAccountInProgress,
  logout,
  setEmailAccountCreateToken,
  setLoginModalScreen,
  completedPostAuthAction,
  setEmailOtpCode,
  setDate,
  setRefreshToken,
  authSuccess,
} from "./redux/features/authSlice";
import {
  setModal,
  dismissModal,
  resetModalState,
  pushModal,
} from "./redux/features/modalSlice";
import {
  setUserPerformance,
  setLastCompletedNewsie,
  setCurrentStreak,
  giveAchievement,
  setCategoryPerformance,
  perfectNewsieCompleted,
  resetUserPerformance,
} from "./redux/features/userPerformanceSlice";
import Modal, { MODALS } from "./Components/Modal";
import {
  LOCAL_STORAGE_KEYS,
  CONFIG,
  USER_ACHIEVEMENTS,
} from "./util/constants";
import AdSenseAd from "./Components/AdSenseAd";
import {
  deleteUser,
  emailOtpAndAuthTokenLogin,
} from "./Components/Modal/modals/Login/api";
import { useNavigate, useParams } from "react-router";
import { CREATE_WITH_EMAIL } from "./Components/Modal/modals/Login";
import {
  clearFetchProfile,
  fetchProfileError,
  fetchProfileSuccess,
  fetchProfileStart,
  resetProfileState,
  fetchProfileInProgress,
} from "./redux/features/profileSlice";
import { setPrices, setDailyTopScores } from "./redux/features/metaSlice";
import { recordGame } from "./API/game.ts";
import { sameDay, firstDateWasYesterday } from "./util/util";
import { useSearchParams } from "react-router-dom";

const { SERVER_API } = CONFIG;
function App() {
  // Nav
  const navigate = useNavigate();

  const {
    accountRecoveryToken,
    date: uriParamDate,
    emailOtpCode,
    email: uriParamEmail,
  } = useParams();

  const authToken = useSelector((state) => state.authReducer.authToken);
  // const completedAu
  const profileRequestStatus = useSelector(
    (state) => state.profileReducer.profileRequestStatus
  );
  useEffect(() => {
    if (profileRequestStatus === "start") {
      if (authToken) {
        dispatch(fetchProfileInProgress());
        fetchProfile(authToken);
      }
    }
    if (profileRequestStatus === "success") dispatch(clearFetchProfile());
  }, [profileRequestStatus, authToken]);

  // Based on emailOtpCode and authToken
  useEffect(() => {
    // if (emailOtpCode) {
    //   dispatch(setEmailOtpCode(emailOtpCode));
    // }
    if (emailOtpCode || authToken)
      authWithEmailOtpAndAuthToken(uriParamEmail, emailOtpCode, authToken);
  }, [emailOtpCode, authToken, uriParamEmail]);

  const authWithEmailOtpAndAuthToken = async (
    email,
    emailOtpCode,
    authToken
  ) => {
    try {
      const apiTokenResonse = await emailOtpAndAuthTokenLogin(
        email,
        emailOtpCode,
        authToken
      );
      const { apiToken } = apiTokenResonse;
      dispatch(setRefreshToken(apiToken));
      dispatch(authSuccess(apiToken));
      dispatch(fetchProfileStart());
      getNews();
    } catch (err) {
      console.log("Error authing with authToken and email otp", { err });
      dispatch(logout());
      dispatch(logoutClearGame());
      localStorage.clear();
      navigate("/");
    }
  };

  useEffect(() => {
    if (uriParamDate) {
      dispatch(setDate(uriParamDate));
    }
  }, []);

  // Local State
  const [dismissingModal, setDismissingModal] = useState(false);

  // SELECTORS
  const dispatch = useDispatch();


  const browserId = useSelector((state) => state.authReducer.browserId);
  const postAuthAction = useSelector(
    (state) => state.authReducer.postAuthAction
  );

  const newsieDate = useSelector((state) => state.authReducer.date);
  const postAuthActionTrigger = useSelector(
    (state) => state.authReducer.postAuthActionTrigger
  );

  const activeModal = useSelector((state) => state.modalReducer.activeModal);
  const isAd = useSelector((state) => state.modalReducer.activeAd);

  // User Performance Selectors
  const lastCompletedNewsie = useSelector(
    (state) => state.userPerformanceReducer.lastCompletedNewsie
  );
  const currentStreak = useSelector(
    (state) => state.userPerformanceReducer.currentStreak
  );
  const categoryResultMap = useSelector(
    (state) => state.userPerformanceReducer.categoryResultMap
  );
  const achievements = useSelector(
    (state) => state.userPerformanceReducer.achievements
  );

  const selectedNewsie = useSelector(
    (state) => state.gameReducer.selectedNewsie
  );

  const gameOver = useSelector(
    (state) => state.gameReducer.gameOver[selectedNewsie]
  );
  const recordedGameStatus = useSelector(
    (state) => state.gameReducer.recordedGameStatus[selectedNewsie]
  );
  const completedLogin = useSelector(
    (state) => state.authReducer.loginStatus === "SUCCESS"
  );
  const isLogout = useSelector((state) => state.authReducer.logout);

  const clickedPlay = useSelector((state) => state.authReducer.clickedPlay);
  const emailOtpCodeState = useSelector(
    (state) => state.authReducer.emailOtpCode
  );

  const lastNewsieUpdate = useSelector(
    (state) => state.gameReducer.lastNewsieUpdateDate
  );
  const words = useSelector((state) => state.gameReducer.words);
  const allNewsies = useSelector((state) => state.gameReducer.allNewsies);
  const winner = useSelector(
    (state) => state.gameReducer.winner[selectedNewsie]
  );
  const stories = useSelector((state) =>
    state.gameReducer.stories ? state.gameReducer.stories[selectedNewsie] : []
  );
  const resultModalShown = useSelector(
    (state) =>
      state.gameReducer.resultModalShown &&
      state.gameReducer.resultModalShown[selectedNewsie]
  );
  const clearGame = useSelector((state) => state.gameReducer.clearGame);
  const deleteUserRequest = useSelector(
    (state) => state.authReducer.deleteUserRequest
  );
  const lastNewsieUpdateDate = useSelector(
    (state) => state.gameReducer.lastNewsieUpdateDate
  );
  const incorrectGuesses = useSelector(
    (state) => state.gameReducer.incorrectGuesses[selectedNewsie]
  );

  // Refs
  const ref = useRef(words);
  const completedTimeRef = useRef();

  const completionTime = useSelector(
    (state) =>
      state.gameReducer.timer[selectedNewsie] &&
      state.gameReducer.timer[selectedNewsie].time,
    (_, b) => {
      completedTimeRef.current = b; // <- collect the new value
      return true; // <- prevent re-render
    }
  );

  const getGameStats = useCallback(() => {
    const date = new Date(lastNewsieUpdateDate);
    return {
      date: date.getTime(),
      category: selectedNewsie,
      incorrectGuesses: incorrectGuesses,
      completionTime: completedTimeRef.current,
    };
  }, [
    lastNewsieUpdateDate,
    selectedNewsie,
    incorrectGuesses,
    completedTimeRef.current,
  ]);

  const fetchProfile = useCallback(async (apiToken) => {
    try {
      const res = await fetch(`${SERVER_API}/profile`, {
        headers: {
          "x-auth-token": `${apiToken}`,
          "x-browser-id": browserId,
        },
      });
      const payload = await res.json();
      const { profile } = payload;
      if (profile.showPasswordModal && !profile.firstLogin) {
        dispatch(pushModal(MODALS.SET_PASSWORD));
      }
      if (profile.firstLogin) {
        dispatch(setModal(MODALS.UPDATE_USERNAME));
      }
      dispatch(fetchProfileSuccess(profile));
      dispatch(setUserPerformance(profile.userPerformance));
      dispatch(setDailyTopScores(profile.dailyTopScores));
    } catch (err) {
      console.log("Error fetching profile!");
      dispatch(fetchProfileError());
      dispatch(logoutClearGame());
      dispatch(logout());
    }
  }, []);

  useEffect(() => {
    if (accountRecoveryToken) {
      dispatch(setEmailAccountCreateToken(accountRecoveryToken));
      setTimeout(() => {
        dispatch(setLoginModalScreen(CREATE_WITH_EMAIL));
        dispatch(setModal(MODALS.LOGIN));
      });
    } else {
      navigate("/");
    }
  }, [accountRecoveryToken]);
  const handleDeleteUser = useCallback(
    async (authToken) => {
      try {
        await deleteUser(authToken, browserId);
        dispatch(deleteAccountSuccess());
        dispatch(logoutClearGame());
      } catch (err) {
        dispatch(deleteAccountError());
      }
    },
    [browserId]
  );

  useEffect(() => {
    if (postAuthActionTrigger) {
      if (postAuthAction === "plus") {
        dispatch(setModal(MODALS.GET_NEWSIE_PLUS));
      }
      dispatch(completedPostAuthAction());
    }
  }, [postAuthAction, postAuthActionTrigger]);

  useEffect(() => {
    switch (deleteUserRequest) {
      case "success":
        dispatch(logout());
        dispatch(resetUserPerformance());
        dispatch(resetProfileState());
        dispatch(resetModalState());
        dispatch(dismissModal());
        break;
      case "error":
        // dispatch(deleteAccountError());
        break;
      case "start":
        handleDeleteUser(authToken);
        dispatch(deleteAccountInProgress());
        break;
      default:
        break;
    }
  }, [deleteUserRequest]);

  const isLoggedIn = useMemo(() => Boolean(authToken), [authToken]);

  // update the ref every time your selector changes
  useEffect(() => {
    ref.current = words[selectedNewsie];
  }, [words, selectedNewsie]);

  useEffect(() => {
    if (completedLogin && activeModal === MODALS.LOGIN) {
      if (!postAuthAction) {
        dispatch(dismissModal(null));
      }
      dispatch(startGame());
    }
  }, [isLoggedIn, activeModal]);

  // Expects yyyy-m-d or yyyy-mm-d yyyy-m-dd
  const normalizeDate = (inputDate) => {
    const dateComponents = inputDate.split("-");
    const year = dateComponents[0];
    const month = dateComponents[1];
    const day = dateComponents[2];
    // Add leading zeros if necessary
    const formattedMonth = month.padStart(2, "0");
    const formattedDay = day.padStart(2, "0");
    // Construct the formatted date
    const formattedDate = `${year}-${formattedMonth}-${formattedDay}`;

    return formattedDate;
  };

  const didMount = useRef(false);

  const recordGameRequest = useCallback(
    async (gameStats) => {
      const performanceUpdate = await recordGame(
        authToken,
        browserId,
        gameStats
      );
      dispatch(fetchProfileStart());
      console.log({ performanceUpdate });
      dispatch(recordGameStatusSuccess(performanceUpdate));
    },
    [authToken, browserId]
  );

  useEffect(() => {
    if (gameOver && !recordedGameStatus) {
      const gameStats = getGameStats();
      if (authToken) {
        // If authenticated save stats on server
        recordGameRequest(gameStats);
      }
    }
  }, [gameOver, recordedGameStatus, browserId, categoryResultMap]);

  useEffect(() => {
    if (winner && didMount && !resultModalShown && recordedGameStatus) {
      dispatch(setModal(MODALS.WINNER));
      dispatch(setResultModalShown());
    }
    didMount.current = true;
  }, [winner, resultModalShown, recordedGameStatus]);

  const shouldUpdateNewsieBoard = useCallback(
    (newsiePayloadDate, newNewsies) => {
      if (!newNewsies) return false;
      // Check time stamp
      if (!lastNewsieUpdate) return true; // First load
      const lastUpdate = new Date(lastNewsieUpdate);
      const newsiePublishDate = new Date(newsiePayloadDate);

      // Update if Dates don't have same ISO string
      if (lastUpdate.toISOString() != newsiePublishDate.toISOString()) {
        console.log("updating newsie board due to date");
        return true;
      }

      // If more newsies have been released since load
      if (
        allNewsies &&
        allNewsies["top-newsie"] &&
        newNewsies["top-newsie"].length > allNewsies["top-newsie"].length
      )
        return true;
      // Adjustment to sub status/auth token
      if (Object.keys(allNewsies).length != Object.keys(newNewsies).length)
        return true;
      // If there is no data stored locally - due to error...update.. this should no longer happen hopefully
      if (!allNewsies || Object.keys(allNewsies).length === 0) return true;
      if (Object.keys(words) === 0) return true;
      return false;
    },
    [allNewsies, words, allNewsies, lastNewsieUpdate]
  );

  const getNews = useCallback(async () => {
    // console.log({ uriParamDate, newsieDate });
    const currentDate = DateTime.now();

    // Format the date as "yyyy-mm-dd"
    currentDate.setZone("America/Los_Angeles");
    const formattedDate = `${currentDate.year}-${currentDate.month}-${currentDate.day}`;

    if (uriParamDate !== newsieDate) {
      const reqParamDate = uriParamDate ? uriParamDate : formattedDate;
      const res = await fetch(`${SERVER_API}/newsies/date/${reqParamDate}`, {
        headers: {
          "x-auth-token": authToken,
          "x-browser-id": browserId,
        },
      });
      if (res.status === 200) {
        const payload = await res.json();
        const newsies = payload.resultMap;
        // const upDate = payload.lastUpdate;
        // const prices = payload.prices?.data;

        // const normalizedUpdateDate = normalizeDate(upDate);

        const normalizedUpdateDate = normalizeDate(reqParamDate);
        // console.log({ normalizedUpdateDate });
        if (shouldUpdateNewsieBoard(normalizedUpdateDate, newsies)) {
          // Todo when should we update or are we just nuking all saved progress now?
          // if (true) {
          dispatch(setAllNewsies(newsies));
          dispatch(setStories(newsies));
          dispatch(setLastNewsieUpdateDate(normalizedUpdateDate));
        } else if (clearGame) {
          dispatch(setStories(allNewsies));
          dispatch(getClue());
          dispatch(completedClearingGame());
        }
        // Update last time we loaded a newsie
        // dispatch(setPrices(prices));
      } else if (res.status === 204) {
        if (clearGame) {
          dispatch(setAllNewsies(allNewsies));
          dispatch(setStories(allNewsies));
          dispatch(completedClearingGame());
        }
      } else if (res.status === 401) {
        localStorage.removeItem(LOCAL_STORAGE_KEYS.SESSION_TOKEN);
        dispatch(logout());
        dispatch(resetUserPerformance());
        dispatch(resetProfileState());
        dispatch(resetModalState());
        dispatch(logoutClearGame());
      }
    }
  }, [
    browserId,
    authToken,
    clearGame,
    allNewsies,
    emailOtpCode,
    newsieDate,
    uriParamDate,
  ]);

  const handleClickHandleClickLogin = () => {
    dispatch(setModal(MODALS.LOGIN));
  };

  const handleDismissModal = () => {
    setDismissingModal(true);
    dispatch(dismissModal());
    setDismissingModal(false);
  };
  
  const handleClickHowToPlay = () => {
    dispatch(setModal("HOW_TO_PLAY"));
  };

  const AppComponent = useMemo(() => {
    // authToken
    if (!authToken)
      return () => (
        <EmailCollection handleClickLoginButton={handleClickHandleClickLogin} />
      );
    if (!clickedPlay) {
      return () => (
        <LandingPageContainer
          isLoggedIn={isLoggedIn}
          dismissModal={handleDismissModal}
          date={lastNewsieUpdate}
          handleClickLoginButton={handleClickHandleClickLogin}
          handleClickHowToPlay={handleClickHowToPlay}
        />
      );
    }
    return () => <AppGameContainer selectedNewsie={selectedNewsie} />;
  }, [
    clickedPlay,
    isLoggedIn,
    handleDismissModal,
    handleClickHandleClickLogin,
    handleClickHowToPlay,
    authToken,
  ]);

  return (
    <div className="App">

      <AppComponent />

      {activeModal && (
        <Modal
          shouldShowModal={Boolean(activeModal)}
          modal={activeModal}
          deleteUserRequest={deleteUserRequest}
          shouldDismissModal={dismissingModal}
          onDismissModal={handleDismissModal}
        />
      )}

      {isAd && <AdSenseAd />}
    </div>
  );
}

export default App;

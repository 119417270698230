import React, { useState } from "react";

import { useDispatch } from "react-redux";
import { useParams } from "react-router";
import { CONFIG } from "../../util/constants";
import { logout } from "../../redux/features/authSlice";

import "./UnsubscribeEmail.css";
const UnsubscribeEmail = () => {
  const dispatch = useDispatch();
  const [completedUnsub, setCompletedUnsub] = useState(false);
  const { email, emailToken } = useParams();

  const unsubscribeEmail = async (email, emailToken) => {
    await fetch(`${CONFIG.SERVER_API}/unsubscribe`, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        "Access-Control-Allow-Credentials": "true",
      },
      body: JSON.stringify({ email, emailToken }),
    });
    dispatch(logout());
    localStorage.clear();
    setCompletedUnsub(true);
  };

  const handleConfirmUnsubscribe = () => {
    unsubscribeEmail(email, emailToken);
  };

  const UnsubscribeConfirmed = () => {
    return (
      <div className="unsubscribe-confirmation-container">
        <h1>We hate to see you leave but love to watch you go...😏</h1>
        <h1>Come on back anytime!</h1>
      </div>
    );
  };

  const YouSureAboutThat = () => {
    return (
      <div className="unsubscribe-are-you-sure-container">
        <h1>Aw man! Are you sure you want to unsubscribe {email}?</h1>
        <button onClick={handleConfirmUnsubscribe}>Unsubscribe</button>
      </div>
    );
  };

  return (
    <div className="unsubscribe-container">
      {completedUnsub ? <UnsubscribeConfirmed /> : <YouSureAboutThat />}
    </div>
  );
};

export default UnsubscribeEmail;

import React, { memo } from "react";

import "./FeedbackModal.css";

const FeedbackModal = (props) => {
  const { text, style = 'ephemeral', type } = props;
  const feedbackModalClassName = `feedback-modal ${style || ''} ${type || ''}`
  return (
    <div key={text} className={feedbackModalClassName}>
      <b>{text}</b>
    </div>
  );
};

export default FeedbackModal;

import React, { useState, useMemo } from "react";

import ReactPlayer from "react-player";
import { useDispatch } from "react-redux";
import { dismissModal } from "../../../../redux/features/modalSlice";
import pointyArrows from "../../../../Assets/gameplay_layout.png";
import clue from "../../../../Assets/clue.gif";
import "./HowToPlay.css";

const TextContent = [
  () => (
    <div className="newsie-screen-3">
      <div>
        <h3>The quiz works like this:</h3>
      </div>
      <div>
        We pick 7 stories from the newsletter and summarize each of them in just{" "}
        <b>3 words</b>
      </div>
      <div>
        <br />
        So a story like:
        <br />
        <br />
        "House Adjourns in disarray as support for Jordan looks weaker than
        expected"
        <br />
        <br />
        Turns into:
        <br />
        <b>House Adjourns Disarray</b>
        <div className="thinker-emoji-container">
          <h1>🤔</h1>
        </div>
      </div>
    </div>
  ),
  () => (
    <div className="newsie-screen-3">
      <div style={{ marginBottom: "30px" }}>
        The first, second and third words of the seven news stories are then
        randomly placed into their corresponding columns.
      </div>
      <div>
        <img src={pointyArrows} alt="loading..."></img>
      </div>
    </div>
  ),
  () => (
    <div className="newsie-screen-3">
      <div>
        Match all seven stories to their abbrevieted headlines. When you get one
        correct you can link to the story to read about it if you're interested.
      </div>
      <div className="video-container">
        <ReactPlayer
          playsinline={true}
          width="80%"
          loop={true}
          volume={0}
          url={"https://d3q50n6jrbieec.cloudfront.net/NEW_GAMEPLAY.MP4"}
          playing={true}
        />
      </div>
    </div>
  ),
  () => (
    <div className="newsie-screen-3">
      <div>
        Its a tough game and if you get stuck there's no shame in using a hint!
      </div>
      <div>
        <img src={clue} alt="loading..."></img>
      </div>
      <h3>Give it a try!</h3>
      {/* Have fun learning about the news!
      <br /> */}
      Share and compete with your friends for the top score!
    </div>
  ),
];

const HowToPlay = () => {
  const dispatch = useDispatch();
  const [instructionIndex, setInstructionIndex] = useState(0);

  // const gifUrl = useMemo(() => INSTRUCTION_GIF_ORDER[instructionIndex](), [instructionIndex])
  // Dirty little trick to force a reload

  const handleClickBack = (e) => {
    e.stopPropagation();
    if (instructionIndex > 0) setInstructionIndex(instructionIndex - 1);
  };

  const handleClickForward = (e) => {
    e.stopPropagation();
    if (instructionIndex < TextContent.length - 1)
      setInstructionIndex(instructionIndex + 1);
  };

  const handleCloseInstructions = (e) => {
    dispatch(dismissModal());
  };

  const ModalContent = TextContent[instructionIndex];
  return (
    <div className="how-to-play">
      <div className="how-to-play-text-container ">
        <ModalContent />
      </div>

      <div className="how-to-play-taps-cta-container">
        <div className="how-to-play-taps-cta-text">Tap to Continue</div>
        <div className="how-to-play-progress-bullets">
          {TextContent.map((e, i) => {
            if (i === instructionIndex) {
              return <b>.</b>;
            }
            return ".";
          })}
        </div>
      </div>

      <div className="tap-screen-container">
        <div className="tap-screen-half" onClick={handleClickBack}>
          <div className="tap-screen-half-back-chevron">
            {instructionIndex !== 0 && "<"}
          </div>
        </div>
        <div className="tap-screen-half" onClick={handleClickForward}>
          <div
            className="close-instructions-xbutton"
            onClick={handleCloseInstructions}
          >
            X
          </div>
          <div className="tap-screen-half-back-chevron">
            {instructionIndex !== TextContent.length - 1 && ">"}
          </div>
        </div>
      </div>
    </div>
  );
};

export default HowToPlay;

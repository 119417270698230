import React from "react";

import "./NewsiePlusModalPurchasePlan.css";

const frequencyToCopyMap = {
  Annualy: { title: "Annual", frequency: 'Annually' },
  Monthly: { title: "Monthly", frequency: 'Monthly' },
};

const NewsiePlusModalPurchasePlan = (props) => {
  const { frequency, price, onSelect } = props;
  const bigLetterPrice =
    frequency === "Annualy"
      ? (price / (100 * 12)).toLocaleString("en-US", {
          style: "currency",
          currency: "USD",
        })
      : (price / 100).toLocaleString("en-US", {
          style: "currency",
          currency: "USD",
        });
  return (
    <div className="get-newsie-plus-modal-purcase-plan" onClick={onSelect}>
      <div className="modal-purchase-plan-header-container">
        <div className="modal-purchase-plan-header">
          {frequencyToCopyMap[frequency].title}
        </div>
      </div>
      <div className="get-newsie-plus-modal-purchase-plan-price-container">
        <strong>FREE week trial!</strong>
        <div className="get-newsie-plus-modal-purchase-plan-price">
          then {bigLetterPrice}/mo
        </div>
        <div className="get-newsie-plus-modal-purchase-plan-billed-as-price">
          Billed as{" "}
          {(price / 100).toLocaleString("en-US", {
            style: "currency",
            currency: "USD",
          })}{" "}
          once {frequencyToCopyMap[frequency].frequency}
        </div>
      </div>
      <div className="get-newsie-plus-modal-purchase-plan-select-button-container">
        <div className="get-newsie-plus-modal-purchase-plan-select-button">
          Select
        </div>
      </div>
    </div>
  );
};

export default NewsiePlusModalPurchasePlan;

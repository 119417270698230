import React from "react";

import { useSelector, useDispatch } from "react-redux";
import { loadStripe } from "@stripe/stripe-js";

import "./GetNewsiePlus.css";

import logo from "../../../../Assets/newsie-triumphant-192-removebg.png";

import NewsiePlusModalPurchasePlan from "./NewsiePlusModalPurchasePlan";

import { CONFIG } from "../../../../util/constants";
import { setModal } from "../../../../redux/features/modalSlice";
import { MODALS } from "../..";

const ANNUALY = "Annualy";
const MONTHLY = "Monthly";

const GENERAL_POINTS = [
  "Access all NEWSIE categories!",
  "Help me pay my server bills!",
  "No ads ever!",
  "Flexible billing - cancel anytime!",
];

const GetNewsiePlus = () => {
  const dispatch = useDispatch();
  const apiToken = useSelector((state) => state.authReducer.authToken);
  const browserId = useSelector((state) => state.authReducer.browserId);

  const initiateCheckout = async (frequency) => {
    if (!apiToken) {
      dispatch(setModal(MODALS.LOGIN));
      return;
    }
    const stripe = await loadStripe(CONFIG.REACT_APP_STRIPE_PK);
    const body = {
      host: `${
        window.location.hostname === "localhost"
          ? `http://${window.location.hostname}`
          : `https://${window.location.hostname}`
      }${window.location.port ? `:${window.location.port}` : ""}`,
      frequency,
    };
    const response = await fetch(
      `${CONFIG.SERVER_API}/create-checkout-session`,
      {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
          "Access-Control-Allow-Credentials": "true",
          "x-auth-token": apiToken,
          "x-browser-id": browserId,
        },
        body: JSON.stringify(body),
      }
    );
    if (response.status === 200) {
      const { session } = await response
        .text()
        .then((text) => JSON.parse(text));
      stripe.redirectToCheckout({
        sessionId: session.id,
      });
    } else {
      // if (response.status === 401) dispatch(logout());
    }
  };

  const handleClickAnnual = (e) => {
    e.stopPropagation();
    initiateCheckout("annual");
  };

  const handleClickMonthly = (e) => {
    e.stopPropagation();
    initiateCheckout("monthly");
  };

  const prices = useSelector((state) => state.metaReducer.prices); // todo set fallback prices
  const priceMap = {};
  prices.forEach((price) => {
    priceMap[price.recurring.interval === "month" ? MONTHLY : ANNUALY] = {
      unit_amount: price.unit_amount,
      interval: price.recurring.interval,
    };
  });
  return (
    <div className="get-newsie-plus-modal-layout">
      <div className="get-newsie-plus-modal-header-container">
        <div className="get-newsie-plus-modal-header">
          <strong>NEWSIE +</strong>
        </div>
      </div>
      <div className="get-newsie-plus-modal-motivation-text-container">
        <div className="get-newsie-plus-modal-motivation-text">
          Take your game to the next level!
        </div>
        <br/>
      </div>
      <div className="get-newsie-plus-logo-container">
        <img src={logo} />
      </div>
      <div className="get-newsie-plus-modal-sales-bullets-container">
        <ul>
          {GENERAL_POINTS.map((p, i) => (
            <li key={i}>{p}</li>
          ))}
        </ul>
      </div>
      <div className="get-newsie-plus-modal-plans-title-container">
        <strong>Subscription Options:</strong>
      </div>
      <div className="get-newsie-plus-modal-purcase-plans-container">
        <NewsiePlusModalPurchasePlan
          frequency={ANNUALY}
          price={priceMap[ANNUALY].unit_amount}
          onSelect={handleClickAnnual}
        />
        <NewsiePlusModalPurchasePlan
          frequency={MONTHLY}
          price={priceMap[MONTHLY].unit_amount}
          onSelect={handleClickMonthly}
        />
      </div>
    </div>
  );
};

export default GetNewsiePlus;

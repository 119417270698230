import React from "react";

import './ViewProfileButton.css'

import profileSvg from "../../../../../../../Assets/profile.svg";

const ViewProfileButton = (props) => {
  const { username, onClickProfile } = props;
  return (
    <div className="view-profile-button-container" onClick={onClickProfile}>
      <div className='view-profile-icon-container'>
      <img
        src={profileSvg}
        style={{ height: 20, width: 20 }}
        alt="website logo"
      />
      </div>
      <div>{username}</div>
    </div>
  );
};

export default ViewProfileButton;

import React, { memo } from "react";

import "./Header.css";
import menuIcon from "../../Assets/menu-icon.svg";
const Header = (props) => {
  const {
    onClickInstructions,
    onClickHamburgerMenu,
    onClickNewsieCategories,
    onClickClueButton,
    encourageClue,
    dontShowClue,
  } = props;
  const clueContainerClassname = `newsie-header-clue-button-container ${
    encourageClue ? "strobe" : ""
  } ${dontShowClue ? "no-clue" : ""}`;
  return (
    <div className="header-container">
      <div
        className="newsie-menu-icon-container"
        onClick={onClickHamburgerMenu}
      >
        <div className="hamburger-menu-icon">
          <img
            src={menuIcon}
            style={{ height: 25, width: 35 }}
            alt="website logo"
            className='hamburger-icon'
          />
        </div>
        <div className="header-newsie-title">
          <div>
              newsie.fyi
          </div>
        </div>
      </div>
      <div className="newsie-header-middle">
      </div>
      <div
        className="instructions-question-icon-layout"
        onClick={onClickClueButton}
      >
        <div className="instructions-question-icon-container">
          <div className="instructions-clue-icon">🔍</div>
          <div className="newsie-header-clue-button-text">Hint?</div>
        </div>
      </div>
      <div className="instructions-question-icon-layout">
        <div
          className="instructions-question-icon-container"
          onClick={onClickInstructions}
        >
          <div className="instructions-question-icon">❓</div>
          <div className="newsie-header-clue-button-text">Instructions</div>
        </div>
      </div>
    </div>
  );
};

export default memo(Header);

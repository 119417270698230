import React, { useState, useMemo, useEffect } from "react";

import { DateTime } from "luxon";

import {
  InvalidUserInputError,
  ServerError,
} from "../../../../../../../util/Errors";

import {
  attemptClaimUsername,
  startClaimUsername,
  attemptClaimUsernameFailureTaken,
  attemptClaimUsernameFailureError,
  attemptClaimUsernameSuccess,
  clearClaimUsernameRequest,
} from "../../../../../../../redux/features/profileSlice";

import {
  REQUEST_LIFE_CYCLE,
  CONFIG,
} from "../../../../../../../util/constants";

import "./UserInfo.css";
import { useDispatch, useSelector } from "react-redux";

// const GENERIC_500 = 'Ooops! Sorry try again later!'

const UsernameChangeStatusMessages = {
  USERNAME_TAKEN: "Sorry that's taken!",
  GENERIC_500: "Ooops! Sorry try again later!",
};

const UserInfo = (props) => {
  const { usernameInputLabel, showCreationDate } = props;
  const username = useSelector((state) => state.profileReducer.username);
  const createdDate = useSelector((state) => state.profileReducer.createdDate);

  const dispatch = useDispatch();
  const [usernameChangeStatusMessage, setUsernameChangeStatusMessage] =
    useState("");
  const [enableUsernameChange, setEnableUsernameChange] = useState(false);
  const [candidateUsername, setCandidateUsername] = useState(username);
  const candidateUsernameRedux = useSelector(
    (state) => state.profileReducer.usernameCandidate
  );
  const changeUsernameRequestStatus = useSelector(
    (state) => state.profileReducer.usernameRequestStatus
  );
  const authToken = useSelector((state) => state.authReducer.authToken);

  const makeClaimUsernameRequest = async (usernameParam, authTokenParam) => {
    let res;
    try {
      res = await fetch(`${CONFIG.SERVER_API}/username`, {
        method: "POST",
        headers: {
          "x-auth-token": authTokenParam,
          "Content-Type": "application/json",
        },
        body: JSON.stringify({ payload: usernameParam, action: "SET" }),
      });
      if (res.status > 200) {
        if (res.status >= 400 && res.status < 500) {
          throw new InvalidUserInputError(
            "400 Response from update username request"
          );
        }
        if (res.status >= 500) {
          setUsernameChangeStatusMessage(
            UsernameChangeStatusMessages.GENERIC_500
          );
          throw new ServerError("500 from update username");
        }
      }
    } catch (err) {
      console.log("Error updating username", { err });
      dispatch(attemptClaimUsernameFailureError());
      throw err;
    }
    if (res.status === 204) {
      // Valid request but already claimed
      dispatch(attemptClaimUsernameFailureTaken());
      setUsernameChangeStatusMessage(
        UsernameChangeStatusMessages.USERNAME_TAKEN
      );
      return;
    }
    const result = await res.json();
    if (result.status === "SUCCESS") {
      dispatch(attemptClaimUsernameSuccess());
    } else {
      dispatch(attemptClaimUsernameFailureTaken());
    }
  };

  //
  useEffect(() => {
    if (changeUsernameRequestStatus === REQUEST_LIFE_CYCLE.START) {
      dispatch(attemptClaimUsername());
      console.log({ candidateUsernameRedux });
      makeClaimUsernameRequest(candidateUsernameRedux, authToken);
    }
  }, [changeUsernameRequestStatus, authToken, candidateUsernameRedux]);

  const { CHANGE_EMAIL_BUTTON_TEXT, onChangeEmailButtonClick } = useMemo(() => {
    if (enableUsernameChange)
      return {
        CHANGE_EMAIL_BUTTON_TEXT: "Change",
        onChangeEmailButtonClick: (e) => {
          e.stopPropagation();
          console.log('clickChange')
          setEnableUsernameChange(false);
          console.log(enableUsernameChange)
        },
      };
    return {
      CHANGE_EMAIL_BUTTON_TEXT: "Claim!",
      onChangeEmailButtonClick: (e) => {
        e.stopPropagation();

        dispatch(startClaimUsername(candidateUsername));
        setEnableUsernameChange(true);
      },
    };
  }, [enableUsernameChange, candidateUsername]);

  const handleUsernameChanged = (e) => {
    if (
      changeUsernameRequestStatus !== REQUEST_LIFE_CYCLE.START &&
      changeUsernameRequestStatus !== REQUEST_LIFE_CYCLE.PENDING
    )
      dispatch(clearClaimUsernameRequest());

    setUsernameChangeStatusMessage("");
    setCandidateUsername(e.target.value);
  };

  return (
    <div className="profile-user-info-container">
      <div className="profile-user-info-username-container">
        <div className="profile-user-info-username-header-row-container">
          <div className="profile-user-info-username-header-container">
            <h4>{usernameInputLabel}</h4>
          </div>
          <div className="profile-user-info-username-message-container">
            {usernameChangeStatusMessage}
          </div>
        </div>
        <div className="profile-user-info-edit-username-input-container">
          <div className="profile-user-info-username-at-container">
            <h3>@</h3>
          </div>
          <input
            type="text"
            value={candidateUsername}
            disabled={enableUsernameChange}
            onChange={handleUsernameChanged}
          />
          <div className="profile-user-info-edit-username-input-button-container">
            <button
              classname="profile-user-info-edit-username-button"
              onClick={onChangeEmailButtonClick}
            >
              <h3>{CHANGE_EMAIL_BUTTON_TEXT}</h3>
            </button>
          </div>
        </div>
      </div>
      {showCreationDate && (
        <div className="profile-user-info-created-date-container">
          <p>Member since: {DateTime.fromMillis(createdDate).toRelative()}</p>
        </div>
      )}
    </div>
  );
};

export default UserInfo;

import { useState, useMemo, useCallback} from "react";
import logo from "../../Assets/newsie-triumphant-192-removebg.png";
import SuccessCheckEmail from "../EmailCollection/pages/SuccessCheckEmail";
import { CONFIG } from '../../util/constants'
import { isValidEmail } from '../../util/util'

import "./ReferralPage.css";

const { SERVER_API } = CONFIG;

const ReferralPage = (props) => {
  const [submitEmailSuccess, setSubmitEmailSuccess] = useState(false);
  const [email, setEmail] = useState();
  const isEmailValid = useMemo(() => isValidEmail(email), [email]);

  const handleEmailInputChanged = (e) => {
    setEmail(e.target.value);
  };

  const submitEmail = useCallback(async () => {
    const refSource = localStorage.getItem("refSource");
    const referralCode = localStorage.getItem("referralCode");


    const response = await fetch(`${SERVER_API}/subscribe`, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify({ email, refSource, referralCode }),
    });

    const { status } = response;
    // Todo handle status
    setSubmitEmailSuccess(true);
  }, [email]);
  const handleSubmitEmaiClicked = useCallback(() => {
    submitEmail();
  }, [email]);


  return (
    <div className="referral-page-container">
      <div className="referral-page-header">
        <div className="referral-page-header-mr-newsie">
          <a href="/">
            <img src={logo} alt="Logo" />
          </a>
        </div>

        <div className="referral-page-header-newsie-fyi"><a href="/">newsie.fyi</a></div>
      </div>
      <div className="referral-page-email-collection-container">
        {submitEmailSuccess ? (
          <SuccessCheckEmail />
        ) : (
          <div>
            <h1>Join The Newsie Community!</h1>
            <h2>You've Been Invited!</h2>
            <p>
              A friend thinks you’d love our newsletter and daily quiz! We’re
              excited to welcome you to our community.
            </p>
            <h2>Why Subscribe?</h2>
            <ul>
              <li>
                <strong>Stay Informed</strong>: Get the latest news and updates
                delivered straight to your inbox.
              </li>
              <li>
                <strong>Competitive Daily Quiz</strong>: Test your memory with
                our daily quiz game based on the newsletter content and compete
                with the community for top scores and prizes and give-aways!
              </li>
              <li>
                <strong>Be Part of a Community</strong>: Join a network of
                like-minded individuals who share your interests.
              </li>
            </ul>
            <h2>Plus, There's More!</h2>
            <p>
              By subscribing through your friend’s referral, you’ll not only get
              our great content, but also contribute to helping your friend earn
              exciting prizes!
            </p>
            <h2>Sign Up Now!</h2>
            <p>
              Enter your email below to start receiving our newsletter, enjoy
              our daily fun quiz-game, and unlock exclusive benefits:
            </p>
            <div className="email-input-container">
              <input
                className="email-input"
                type="email"
                placeholder="smart-and-funny@gmail.com"
                value={email}
                onChange={handleEmailInputChanged}
                autoComplete="email"
              />
              <button
                onClick={handleSubmitEmaiClicked}
                disabled={!isEmailValid}
              >
                Subscribe
              </button>
            </div>

            <p>
              **We respect your privacy and promise to keep your information
              safe. You can unsubscribe at any time, but we hope you’ll love
              being part of our community!
            </p>
          </div>
        )}
      </div>
    </div>
  );
};

export default ReferralPage;

import React, { useState } from "react";

import { useDispatch, useSelector } from "react-redux";

import "./HamburgerMenu.css";

import { logout } from "../../../../redux/features/authSlice";
import { logoutClearGame } from "../../../../redux/features/gameSlice";
import { resetUserPerformance } from "../../../../redux/features/userPerformanceSlice";
import {
  dismissModal,
  setModal,
  setDynamicModal,
} from "../../../../redux/features/modalSlice";

import { MODALS, CONFIRMATION_MODAL_VARIANTS } from "../../../Modal";
import Default from "./pages/Default";
import Settings from "./pages/Settings";
import Profile from "./pages/Profile";
import { LOCAL_STORAGE_KEYS } from "../../../../util/constants";

const HAMBURER_MENU_PAGES = {
  DEFAULT: "DEFAULT",
  SETTINGS: "SETTINGS",
  PROFILE: "PROFILE",
};

const HamburgerMenu = () => {
  const loggedIn = useSelector((state) => state.authReducer.authToken);
  const hasSubscription = useSelector(
    (state) => state.profileReducer.hasSubscription
  );
  const username = useSelector((state) => state.profileReducer.username);
  const createdDate = useSelector((state) => state.profileReducer.createdDate);

  const dispatch = useDispatch();
  const [hamburgerMenuPage, setHamburgerMenuPage] = useState(
    HAMBURER_MENU_PAGES.DEFAULT
  );

  const handleClickLogout = () => {
    localStorage.removeItem(LOCAL_STORAGE_KEYS.AUTH);
    localStorage.removeItem(LOCAL_STORAGE_KEYS.GAME);
    localStorage.removeItem(LOCAL_STORAGE_KEYS.USER_PERFORMANCE);
    dispatch(resetUserPerformance());
    dispatch(logout());
    dispatch(logoutClearGame());
    dispatch(dismissModal());
  };

  const handleClickLogin = (e) => {
    e.stopPropagation();
    dispatch(setModal(MODALS.LOGIN));
  };

  const handleClickDelete = (e) => {
    e.stopPropagation();
    dispatch(
      setDynamicModal({
        modalType: MODALS.CONFIRMATION_MODAL,
        variant: CONFIRMATION_MODAL_VARIANTS.CONFIRM_USER_DELETE,
      })
    );
  };

  const handleClickBack = (e) => {
    e.stopPropagation();
    setHamburgerMenuPage(HAMBURER_MENU_PAGES.DEFAULT);
  };

  const handleClickSettings = (e) => {
    e.stopPropagation();
    setHamburgerMenuPage(HAMBURER_MENU_PAGES.SETTINGS);
  };

  const handleClickProfile = (e) => {
    e.stopPropagation();
    setHamburgerMenuPage(HAMBURER_MENU_PAGES.PROFILE);
  };

  const handleClickGetNewsiePlus = (e) => {
    e.stopPropagation();
    dispatch(setModal(MODALS.GET_NEWSIE_PLUS));
  };

  const handleClickLoginToUseNewsiePlus = (e) => {
    e.stopPropagation();
    dispatch(setModal(MODALS.LOGIN));
  };

  return (
    <div className="hamburger-menu-internal-layout">
      {hamburgerMenuPage === HAMBURER_MENU_PAGES.DEFAULT && (
        <Default
          username={username}
          onClickGetNewsiePlus={handleClickGetNewsiePlus}
          hasSubscription={hasSubscription}
          onClickSettings={handleClickSettings}
          onClickProfile={handleClickProfile}
          onClickLogin={handleClickLogin}
          onClickLogout={handleClickLogout}
          onClickSettings={handleClickSettings}
          loggedIn={loggedIn}
          onCickLoginToUseNewsiePlus={handleClickLoginToUseNewsiePlus}
        />
      )}
      {hamburgerMenuPage === HAMBURER_MENU_PAGES.SETTINGS && (
        <Settings
          onClickDelete={handleClickDelete}
          loggedIn={loggedIn}
          onClickBack={handleClickBack}
        />
      )}
      {hamburgerMenuPage === HAMBURER_MENU_PAGES.PROFILE && (
        <Profile createdDate={createdDate} username={username} />
      )}
    </div>
  );
};

export default HamburgerMenu;

export const getDateString = (date) => {
  const dayOfTheWeek = new Date(date).toLocaleString("en", { weekday: "long" });
  const day = new Date(date).getDate();
  const month = new Date(date).toLocaleString('default', { month: 'long' });
  const year = new Date(date).getFullYear();
  return `${dayOfTheWeek}, ${month} ${day}, ${year}`
}
export const getDateStringNoDay = (date) => {
  const day = new Date(date).getDate();
  const month = new Date(date).toLocaleString('default', { month: 'long' });
  const year = new Date(date).getFullYear();
  return `${month} ${day}, ${year}`
}

export const isValidPassword = (password) => {
  const regex = /^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)(?=.*[!@#$%^&*()_+-={}:"<>?,.\/;'\\])?[a-zA-Z0-9!@#$%^&*()_+-={}:"<>?,.\/;'\\\s]{8,}$/;
  return regex.test(password);
}

// Returnn if two dates are same day
export function sameDay(d1, d2) {
  return d1.getFullYear() === d2.getFullYear() &&
    d1.getMonth() === d2.getMonth() &&
    d1.getDate() === d2.getDate();
}

export function firstDateWasYesterday(d1, d2) {
  return d1.getFullYear() === d2.getFullYear() &&
    d1.getMonth() === d2.getMonth() &&
    d1.getDate() === d2.getDate() - 1;
}

export function isValidEmail(email) {
  // Regular expression for a valid email address
  const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;

  // Test the email against the regex pattern
  return emailRegex.test(email);
}
import React, { useMemo, useEffect } from "react";

import { useSelector, useDispatch } from "react-redux";
import UserStat from "../UserStat";
import Achievement from "../Achievement";
import {
  USER_ACHIEVEMENTS,
  EMOJI_CATEGORY_TITLES,
  ACHIEVEMENT_ASSETS,
} from "../../../../../../../util/constants";

import "./PerformanceCenter.css";
import { setModal } from "../../../../../../../redux/features/modalSlice";
import { MODALS } from "../../../../..";

const PerformanceCenter = () => {
  const dispatch = useDispatch();
  const currentStreak = useSelector(
    (state) => state.userPerformanceReducer.currentStreak
  );
  const longestStreak = useSelector(
    (state) => state.userPerformanceReducer.longestStreak
  );
  const achievements = useSelector(
    (state) => state.userPerformanceReducer.achievements
  );
  const perfectNewsies = useSelector(
    (state) => state.userPerformanceReducer.perfectNewsies
  );

  const avgCompletionTime = useSelector(
    (state) => state.userPerformanceReducer.averageNewsieCompletionTime
  );

  const formattedCompletionTime = useMemo(() => {
    const minutes = Math.floor(avgCompletionTime / 60);
    const seconds =
      avgCompletionTime % 60 < 10
        ? Number(`0${avgCompletionTime % 60}`).toFixed(0)
        : Number(avgCompletionTime % 60).toFixed(0);
    return `${minutes}:${seconds}`;
  }, [avgCompletionTime]);
  const avgMissedGuesses = useSelector(
    (state) =>
      state.userPerformanceReducer.averageMissedGuesses &&
      state.userPerformanceReducer.averageMissedGuesses.toFixed(2)
  );

  const hydratedAchievements = useMemo(
    () => (achievements ? achievements.map((n) => ACHIEVEMENT_ASSETS[n]) : []),
    [achievements]
  );
  const authToken = useSelector((state) => state.authReducer.authToken);
  const bestCategory = useSelector((state) => {
    const categoryResultMap = state.userPerformanceReducer.categoryResultMap;
    let bestWinLossRatio;
    let bestCategory;
    Object.keys(categoryResultMap).forEach((category) => {
      console.log(categoryResultMap[category]);
      if (!categoryResultMap[category].won && !categoryResultMap[category].lost)
        return;
      const {
        won,
        lost,
        correctGuesses,
        missedStories,
        perfectCategoryNewsies,
      } = categoryResultMap[category];
      const winLossRatio = won / (won + lost);
      if (!bestWinLossRatio) {
        bestWinLossRatio = winLossRatio;
        bestCategory = category;
      } else if (winLossRatio > bestWinLossRatio) {
        bestWinLossRatio = winLossRatio;
        bestCategory = category;
      }
    });
    return bestCategory;
  });

  const worstCategory = useSelector((state) => {
    const categoryResultMap = state.userPerformanceReducer.categoryResultMap;
    let worstWinLossRatio;
    let worstCategory;
    Object.keys(categoryResultMap).forEach((category) => {
      if (!categoryResultMap[category].won && !categoryResultMap[category].lost)
        return;
      const {
        won,
        lost,
        correctGuesses,
        missedStories,
        perfectCategoryNewsies,
      } = categoryResultMap[category];
      const lossWinRatio = lost / (won + lost);
      if (!worstWinLossRatio) {
        worstWinLossRatio = lossWinRatio;
        worstCategory = category;
      } else if (lossWinRatio < worstWinLossRatio) {
        worstWinLossRatio = worstWinLossRatio;
        worstCategory = category;
      }
    });
    return worstCategory;
  });
  useEffect(() => {
    console.log({ bestCategory, worstCategory });
  }, [bestCategory, worstCategory]);

  const STATS = useMemo(
    () => [
      { label: "Current Streak", value: currentStreak, icon: "📈" },
      { label: "Longest Streak", value: longestStreak, icon: "🙌" },
      { label: "Perfect Newsies", value: perfectNewsies, icon: "💯" },
      {
        label: "Avg. Completion Time",
        value: formattedCompletionTime,
        icon: "🕒 ",
      },
      { label: "Avg. Missed Guesses", value: avgMissedGuesses, icon: "❌" },
      // {
      //   label: "Best Category",
      //   value: bestCategory,
      //   isPng: bestCategory === "top-newsie",
      //   icon: `${
      //     Boolean(EMOJI_CATEGORY_TITLES[bestCategory])
      //       ? EMOJI_CATEGORY_TITLES[bestCategory][1]
      //       : "TBD"
      //   }`,
      //   hidden: !Boolean(authToken),
      // },
      // {
      //   label: "Worst Category",
      //   value: worstCategory,
      //   isPng: worstCategory === "top-newsie",
      //   icon: `${
      //     Boolean(EMOJI_CATEGORY_TITLES[worstCategory])
      //       ? EMOJI_CATEGORY_TITLES[worstCategory][1]
      //       : "TBD"
      //   }`,
      //   hidden: !Boolean(authToken),
      // },
      {
        label: "Newsie Rank",
        value: "95%",
        icon: "🏅",
        hidden: !Boolean(authToken),
      },
    ],
    [
      currentStreak,
      longestStreak,
      authToken,
      perfectNewsies,
      bestCategory,
      worstCategory,
      formattedCompletionTime,
    ]
  );

  const handleClickLogin = (e) => {
    e.stopPropagation();
    dispatch(setModal(MODALS.LOGIN));
  };

  const achievemenetRowClassName = `achievement-row ${
    achievements.length === 0 && "empty"
  }`;

  return (
    <div className="performance-center-container">
      <div className="performance-center">
        <div className="achievemenet-row-header">Achievements:</div>
        <div className={achievemenetRowClassName}>
          {achievements.length === 0 && (
            <div className="achievement-row-empty-cta-text">
              Complete newsies to unlock Awards!
            </div>
          )}
          {hydratedAchievements.map((a, i) => (
            <Achievement key={a.id} title={a.title} id={a.id} />
          ))}
        </div>
        <div className="achievemenet-row-header"></div>
      </div>
      <div className="stats-center">
        <div className="achievemenet-row-header">Performance Stats:</div>
        <div className="performace-stats-container">
          {!authToken && (
            <div
              className="logged-out-stats-cta-banner-background"
              onClick={handleClickLogin}
            >
              <div className="logged-out-stats-cta-banner">
                Log in to see all stats!
              </div>
            </div>
          )}
          {STATS.map((s, i) => (
            <UserStat
              key={i}
              label={s.label}
              value={s.value}
              icon={s.icon}
              hidden={s.hidden}
              isPng={s.isPng}
            />
          ))}
        </div>
      </div>
    </div>
  );
};

export default PerformanceCenter;

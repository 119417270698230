import React, { useState } from 'react';

import { useDispatch, useSelector } from 'react-redux';
import { DEFAULT } from '../..';

import { closeAuthModal, setLoginModalScreen } from '../../../../../../redux/features/authSlice';
import { dismissModal } from '../../../../../../redux/features/modalSlice';

import './CheckYourEmail.css'


export const CHECK_YOUR_EMAIL_VARIANTS = {
  CREATE: 'CREATE',
  RECOVERY: 'RECOVERY'
}


const VARIANT_TO_COPY_MAP = {
  'CREATE': (email) => `Almost done! We sent a link to ${email} to verify your email.`,
  'RECOVERY': (email) => ` We sent an email to ${email} which has a link to get back in to your account.`
}

const CheckYourEmail = (props) => {
  const { variant } = props;
  const dispatch = useDispatch();

  const authEmail = useSelector((state) => state.authReducer.authEmail);

  const handleClickOk = () => {
    dispatch(setLoginModalScreen(DEFAULT));
    dispatch(closeAuthModal());
    dispatch(dismissModal())
  };

  return (<div className='check-your-email-container'>
    <h3>Check your email</h3>
    <div className='check-your-email-instructions'>
      {VARIANT_TO_COPY_MAP[variant]}
    </div>

    <button onClick={handleClickOk}>OK</button>
  </div>);
}



export default CheckYourEmail;
import React from "react";
import { useSelector } from "react-redux";

import "./WordColumnsContainer.css";
import WordColumn from "../WordColumn";

const WordColumnsContainer = () => {
  const selectedNewsie = useSelector(
    (state) => state.gameReducer.selectedNewsie
  );
  const words = useSelector((state) => state.gameReducer.words)[selectedNewsie];
  const selected = useSelector((state) => state.gameReducer.selectedWords);
  const rowsRemaining = words ? words[0].length : 0;
  const incorrectGuess = useSelector(
    (state) => state.gameReducer.incorrectGuess
  );
  const correctGuess = useSelector((state) => state.gameReducer.correctGuess);
  const className = `word-columns-container ${rowsRemaining ? "hidden" : ""}`;
  return (
    <div className="word-columns-container">
      {((words && rowsRemaining) || correctGuess) &&
        words.map((wordColumn, i) => (
          <WordColumn
            key={i}
            correct={correctGuess}
            incorrect={incorrectGuess}
            key={i}
            columnIndex={i}
            words={wordColumn}
            selected={selected && selected[i]}
          />
        ))}
    </div>
  );
};

export default WordColumnsContainer;

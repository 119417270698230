import React from 'react';

import { useSelector } from 'react-redux'

import './ConfirmationModal.css';

import store from '../../../../redux/store';

import { startDeleteAccount } from '../../../../redux/features/authSlice'
import { dismissModal } from '../../../../redux/features/modalSlice'

import logo from '../../../../Assets/sad-newsie-192-removebg.png'


const CONFIRMATION_MODAL_VARIANTS = {
  CONFIRM_USER_DELETE: {
    title: 'So, you want to delete your account?',
    confirmButtonText: 'yes',
    cancelButtonText: 'no',
    onConfirm: (e) => {
      e.stopPropagation();
      store.dispatch(startDeleteAccount());
    },
    onCancel: (e) => {
      e.stopPropagation();
      store.dispatch(dismissModal())
    },
    image: logo
  }
};

const ConfirmationModal = () => {
  const variant = useSelector(state => state.modalReducer.dynamicModalVariant)
  const { title, confirmButtonText, cancelButtonText, onConfirm, onCancel, image } = CONFIRMATION_MODAL_VARIANTS[variant];

  return <div className='confirmation-modal-layout'>
    <div className='confirmation-modal-title-container'>
      <div className='confirmation-modal-title'>
        <strong>{title}</strong>
      </div>
    </div>
    <div className='confirmation-modal-image-container'>
      <img src={logo} />
    </div>

    <div className='confirmation-buttons-container'>
      <div className='confirmation-modal-button confirm' onClick={onConfirm}>
        <strong>{confirmButtonText}</strong>
      </div>
      <div className='confirmation-modal-button cancel' onClick={onCancel}>
        <strong>{cancelButtonText}</strong>
      </div>
    </div>

  </div>
}

export default ConfirmationModal
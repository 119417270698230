import React from 'react';

import logo from '../../../../../Assets/newsie-triumphant-192-removebg.png'; // Tell webpack this JS file uses this image


import './NewsieMascot.css';

const NewsieMascot = () => {
  return (<div className='hamburger-menu-newsie-mascot-container'>
  <img src={logo} width={120} height={120} alt="Logo" />
</div>);
};

export default NewsieMascot;
import React from 'react';

import StoryContainer from '../StoryContainer';

import './SolvedStoriesContainer.css';

const SolvedStoriesContainer = (props) => {
  const { solvedStories, newStorySolved, remainingStories} = props;
  const className = `solved-stories-container ${remainingStories === 0 ? 'should-flex': ''}`
  return (<div className={className}>
    {solvedStories.map((solvedStory, i) => {
      const showStoryTitle = solvedStories.length - 1 === i && newStorySolved;
      return <StoryContainer key={i} story={solvedStory} showTitle={showStoryTitle} />
    })}
  </div>)
};

export default SolvedStoriesContainer;

import React from "react";

import "./GameContainer.css";

import WordColumnsContainer from "../WordColumnsContainer";
import GameDetails from "../../ActionPanel/GameDetails";
// import FeedbackModal from "../../FeedbackModal";

const GameContainer = (props) => {
  const { remainingStories } = props;
  const className = `game-container ${remainingStories > 0 ? 'should-flex': ''}`
  return (
    <div className={className}>
      {remainingStories > 0 && <WordColumnsContainer />}
      <GameDetails />
    </div>
  );
};

export default GameContainer;

import { createSlice } from "@reduxjs/toolkit";
import { LOCAL_STORAGE_KEYS } from "../../util/constants";
import { CONFIG } from "../../util/constants";

const initialState = {
  modalStack: [],
  activeModal: null,
  dynamicModalVariant: null,
  activeAd: false,
};

export const modalSlice = createSlice({
  name: LOCAL_STORAGE_KEYS.MODAL,
  initialState,
  reducers: {
    setDynamicModal: (state, action) => {
      const { modalType, variant } = action.payload;
      state.dynamicModalVariant = variant;
      state.activeModal = modalType;
    },
    setModal: (state, action) => {
      state.activeModal = action.payload;
      state.modalStack = [action.payload, ...state.modalStack]
    },
    dismissModal: (state) => {
      state.dynamicModalVariant = null;
      state.modalStack.shift();
      state.activeModal = state.modalStack[0];
    },
    pushModal: (state, action) => {
      state.modalStack = [...state.modalStack, action.payload];
    },
    dismissAd: (state) => {
      state.activeAd = false;
    },
    setAd: (state) => {
      if (CONFIG.ADS_ENABLED) {
        state.activeAd = true;
      }
    },
    resetModalState: (state) => {
      return { ...initialState };
    },
  },
});

// Additional logic for saving and loading from localStorage
export const loadModalFromLocalStorage = () => {
  const storedModal = localStorage.getItem(LOCAL_STORAGE_KEYS.MODAL);
  if (!storedModal) return modalSlice.initialState;
  const newModalSlice = JSON.parse(storedModal);
  newModalSlice.modalStack = [];
  return newModalSlice;
};

export const saveModalToLocalStorage = (modal) => {
  const persistedModalState = { ...modal, activeModal: null };
  localStorage.setItem(
    LOCAL_STORAGE_KEYS.MODAL,
    JSON.stringify(persistedModalState)
  );
};

// Action creators are generated for each case reducer function
export const {
  setModal,
  dismissModal,
  pushModal,
  setAd,
  dismissAd,
  setDynamicModal,
  resetModalState,
} = modalSlice.actions;

export default modalSlice.reducer;

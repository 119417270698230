import React from 'react';

import './WordColumn.css'

import WordCard from '../WordCard';

const WordColumn = (props) => {
  const { columnIndex, words, selected, incorrect, correct } = props;
  const className = `word-column ${words.length ? '' : 'empty'}`
  return (<div className={className}>
    {words.map((word, i) => <WordCard key={i} correct={correct} incorrect={incorrect} key={i} columnIndex={columnIndex} word={word} wordIndex={i} isSelected={selected===i}/>)}
  </div>);
};

export default WordColumn;
import { CONFIG, LOCAL_STORAGE_KEYS } from '../../../../util/constants';
import {ServerError, InvalidUserInputError} from '../../../../util/Errors'
const { SERVER_API } = CONFIG;



// Given emailotp - may be null
// Given authToken - may be null
// if !authToken and emailotp -> client can set new authToken to result of call, kill otp
// if !emailotp and authToken -> client can set new authToken to result of call (ex logged in user going to older newsie)
// if emailotp and authToken but emailotp user != authToken user => client can set new authToken to result of call - FULL LOGOUT and reauth of another user... 
//
export const emailOtpAndAuthTokenLogin = async (email, emailOtp, authToken) => {
  let res;
  try {
    res = await fetch(`${SERVER_API}/login/email/otp`, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify({ email, emailOtp, authToken }),
    })
    if (res.status > 200) {
      if (res.status >= 400 && res.status < 500) {
        throw new InvalidUserInputError('400 Response from login with EmailOtp');
      }
      if (res.status >= 500) {
        throw new ServerError('500 from login with EmailOtp')
      }
    }
  } catch (err) {
    console.log('Error logging in with email otp', { err })
    throw err;
  }
  return await res.json();
}


// Todo: This should be loaded from redux probs
// const browserId = localStorage.getItem(LOCAL_STORAGE_KEYS.BROWSER_ID);


export const deleteUser = async (apiToken, browserId) => {
  let res;
  try {
    res = await fetch(`${SERVER_API}/account`, {
      method: "DELETE",
      headers: {
        "x-auth-token": `${apiToken}`,
        'x-browser-id': browserId
      }
    })
    if (res.status > 200) {
      if (res.status >= 400 && res.status < 500) {
        throw new InvalidUserInputError('400 Response from login with Email');
      }
      if (res.status >= 500) {
        throw new ServerError('500 from login with Email')
      }
    }
  } catch (err) {
    console.log('Error logging in with email', { err })
    throw err;
  }
  return res;
}

export const sendAccountCreationEmail = async (email, token, browserId) => {
  let res;
  try {
    res = await fetch(`${SERVER_API}/login/email/send`, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        'x-browser-id': browserId

      },
      body: JSON.stringify({ email, token }),
    })
    if (res.status > 200) {
      if (res.status >= 400 && res.status < 500) {
        throw new InvalidUserInputError('400 Response from login with Email');
      }
      if (res.status >= 500) {
        throw new ServerError('500 from login with Email')
      }
    }
  } catch (err) {
    console.log('Error checking email', { err })
  }
  return res;
}

export const checkEmail = async (email, browserId) => {
  let res;
  try {
    res = await fetch(`${SERVER_API}/login/email/check`, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        'x-browser-id': browserId

      },
      body: JSON.stringify({ email }),
    })
    if (res.status > 200) {
      if (res.status >= 400 && res.status < 500) {
        throw new InvalidUserInputError('400 Response from login with Email');
      }
      if (res.status >= 500) {
        throw new ServerError('500 from login with Email')
      }
    }
  } catch (err) {
    console.log('Error checking email', { err })
  }
  return await res.json();
}

export const loginWithEmail = async (email, password, browserId) => {
  let res;
  try {
    res = await fetch(`${SERVER_API}/login/email`, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        'x-browser-id': browserId

      },
      body: JSON.stringify({ email, password }),
    })
    if (res.status > 200) {
      if (res.status >= 400 && res.status < 500) {
        throw new InvalidUserInputError('400 Response from login with Email');
      }
      if (res.status >= 500) {
        throw new ServerError('500 from login with Email')
      }
    }
  } catch (err) {
    console.log('Error logging in with email', { err })
    throw err;
  }
  return await res.json();
}

export const createAccountWithEmail = async (emailCreationToken, password, browserId, userPerformance) => {
  let res;
  try {
    res = await fetch(`${SERVER_API}/account/create`, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        'x-browser-id': browserId
      },
      body: JSON.stringify({ emailCreationToken, password, userPerformance }),
    })
    if (res.status > 200) {
      if (res.status >= 400 && res.status < 500) {
        throw new InvalidUserInputError('400 Response from login with Email');
      }
      if (res.status >= 500) {
        throw new ServerError('500 from login with Email')
      }
    }
  } catch (err) {
    console.log('Error creating account with email', { err })
  }
  return await res.json();
}

// Account Recovery
export const forgotPassword = async (email, token, browserId) => {
  let res;
  try {
    res = await fetch(`${SERVER_API}/login/password/forgot`, {
      method: "POST", // or 'PUT'
      headers: {
        "Content-Type": "application/json",
        'x-browser-id': browserId

      },
      body: JSON.stringify({ email, token }),
    })
    if (res.status > 200) {
      if (res.status >= 400 && res.status < 500) {
        throw new InvalidUserInputError('400 Response from login with Email');
      }
      if (res.status >= 500) {
        throw new ServerError('500 from login with Email')
      }
    }
  } catch (err) {
    console.log('Error requesting account recovery', { err })
  }
  return;
}

export const resetPassword = async (password, accountRecoveryCode) => {
  let res;
  try {
    res = await fetch(`${SERVER_API}/login/password/reset`, {
      method: "POST", // or 'PUT'
      headers: {
        "Content-Type": "application/json"
      },
      body: JSON.stringify({ password, accountRecoveryCode }),
    })
    if (res.status > 200) {
      if (res.status >= 400 && res.status < 500) {
        throw new InvalidUserInputError('400 Response from login with Email');
      }
      if (res.status >= 500) {
        throw new ServerError('500 from login with Email')
      }
    }
  } catch (err) {
    console.log('Error resetting password', { err })
  }
  return;
}

export const resetPasswordAuthed = async (password, authToken) => {
  let res;
  try {
    res = await fetch(`${SERVER_API}/account/password/reset`, {
      method: "POST", // or 'PUT'
      headers: {
        "Content-Type": "application/json",
        'x-auth-token': authToken
      },
      body: JSON.stringify({ password }),
    })
    if (res.status > 200) {
      if (res.status >= 400 && res.status < 500) {
        throw new InvalidUserInputError('400 Response from resetPasswordAuthed');
      }
      if (res.status >= 500) {
        throw new ServerError('500 from resetPasswordAuthed')
      }
    }
  } catch (err) {
    console.log('Error resetting password in authed state!', { err })
  }
  return;
}

// 3rd Party logins
export const loginWithGoogle = async (code, browserId, userPerformance) => {
  let res;
  try {
    res = await fetch(`${SERVER_API}/login/google`, {
      method: "POST", // or 'PUT'
      headers: {
        "Content-Type": "application/json",
        'x-browser-id': browserId

      },
      body: JSON.stringify({ code, userPerformance }),
    })
    if (res.status > 200) {
      if (res.status >= 400 && res.status < 500) {
        throw new InvalidUserInputError('400 Response from login with Email');
      }
      if (res.status >= 500) {
        throw new ServerError('500 from login with Email')
      }
    }
  } catch (err) {
    console.log('Error logging in with google!', { err })
  }
  return res;
}

// export const loginWithTwitter = async (code, state, browserId, userPerformance) => {
//   let res;
//   try {
//     res = await fetch(`${SERVER_API}/login/twitter`, {
//       method: "POST", // or 'PUT'
//       headers: {
//         "Content-Type": "application/json",
//         'x-browser-id': browserId

//       },
//       body: JSON.stringify({ code, state, userPerformance}),
//     });
//     if (res.status > 200) {
//       if (res.status >= 400 && res.status < 500) {
//         throw InvalidUserInputError('400 Response from login with Email');
//       }
//       if (res.status >= 500) {
//         throw ServerError('500 from login with Email')
//       }
//     }
//   } catch (err) {
//     console.log('Error logging in with twitter!', { err })
//   }
//   return res;
// }

// export const loginWithFacebook = async (code, browserId, userPerformance) => {
//   let res;
//   try {
//     res = await fetch(`${SERVER_API}/login/facebook`, {
//       method: "POST", // or 'PUT'
//       headers: {
//         "Content-Type": "application/json",
//         'x-browser-id': browserId
//       },
//       body: JSON.stringify({ code }),
//     })
//     if (res.status > 200) {
//       if (res.status >= 400 && res.status < 500) {
//         throw InvalidUserInputError('400 Response from login with Email');
//       }
//       if (res.status >= 500) {
//         throw ServerError('500 from login with Email')
//       }
//     }
//   } catch (err) {
//     console.log('Error logging in with facebook!', { err })
//   }
//   return res;

// }

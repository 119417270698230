import React, { useCallback } from "react";

import { useDispatch } from "react-redux";
import { EMOJI_CATEGORY_TITLES } from "../../../../../util/constants";

import topNewsie from "../../../../../Assets/top-newsie.png";

import "./NewsieCategoryButton.css";
import { setModal } from "../../../../../redux/features/modalSlice";
import { setPostAuthAction } from "../../../../../redux/features/authSlice";

import { MODALS } from "../../../index";

const NewsieCategoryButton = (props) => {
  const dispatch = useDispatch();
  const { category, onClick, isPng, enabled, loggedIn } = props;
  const handleNewsieButtonClick = useCallback(
    (e) => {
      if (!enabled) {
        e.stopPropagation();
        if (!loggedIn) {
          dispatch(setPostAuthAction("plus"));
          dispatch(setModal(MODALS.LOGIN));
        } else {
          dispatch(setModal(MODALS.GET_NEWSIE_PLUS));
        }
      } else {
        onClick(category);
      }
    },
    [category, enabled]
  );

  const className = `newsie-category ${enabled ? "enabled" : "disabled"}`;
  return (
    <div className={className} onClick={handleNewsieButtonClick}>
      <div className="newsie-category-image-container">
        {!isPng && EMOJI_CATEGORY_TITLES[category][1]}
        {isPng && <img src={topNewsie} />}
      </div>
      <div className="newsie-category-text-container">
        {EMOJI_CATEGORY_TITLES[category][0]}
      </div>
    </div>
  );
};

export default NewsieCategoryButton;

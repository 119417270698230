import React from "react";
import { useSelector, useDispatch } from "react-redux";
import GuessButton from "../GuessButton";
import "./GameDetails.css";

import Stopwatch from "./Stopwatch";
import IncorrectGuesses from "./IncorrectGuesses";
const GameDetails = () => {
  const dispatch = useDispatch();
  const selectedNewsie = useSelector(
    (state) => state.gameReducer.selectedNewsie
  );

  const incorrectGuesses = useSelector(
    (state) => state.gameReducer.incorrectGuesses[selectedNewsie]
  );

  const time = useSelector((state) =>
    state.gameReducer.timer && state.gameReducer.timer[selectedNewsie]
      ? state.gameReducer.timer[selectedNewsie].time
      : -1
  );

  const guessable = useSelector((state) => state.gameReducer.guessable);
  const gameOver = useSelector(
    (state) => state.gameReducer.gameOver[selectedNewsie]
  );

  const SCORE = 1000 - time - 10 * incorrectGuesses;

  return (
    <div className="game-details">
      <Stopwatch />
      <IncorrectGuesses incorrectGuesses={incorrectGuesses} />
      <GuessButton
        guessable={guessable}
        gameOver={gameOver}
        incorrectGuesses={incorrectGuesses}
        time={time}
        score={SCORE}
      />
    </div>
  );
};

export default GameDetails;

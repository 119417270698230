import React, { useState, useMemo } from "react";

import { useDispatch, useSelector } from "react-redux";
import { dismissModal } from "../../../../redux/features/modalSlice";

import { isValidPassword } from "../../../../util/util";
import {
  resetPassword,
  resetPasswordAuthed,
} from "../../../Modal/modals/Login/api";
import UserInfo from "../HamburgerMenu/pages/Profile/UserInfo";

import "./SetPassword.css";

const SetPassword = () => {
  const [password, setPassword] = useState();
  const [verifyPassword, setVerifyPassword] = useState();
  const dispatch = useDispatch();
  const authToken = useSelector((state) => state.authReducer.authToken);

  const fistPasswordValid = useMemo(
    () => isValidPassword(password),
    [password, isValidPassword]
  );
  const verifyPasswordMatches = useMemo(
    () => password === verifyPassword,
    [password, verifyPassword]
  );
  const canSubmitResetPasswordRequest = useMemo(
    () => fistPasswordValid && verifyPasswordMatches,
    [fistPasswordValid, verifyPasswordMatches]
  );

  const handlePasswordChange = (e) => {
    setPassword(e.target.value);
  };

  const handleVerifyPasswordChange = (e) => {
    setVerifyPassword(e.target.value);
  };

  const resetPasswordRequest = async () => {
    await resetPasswordAuthed(password, authToken);
    dispatch(dismissModal());
    // Navigate back to App
    // navigate('/');
  };
  const handleResetPassword = () => {
    resetPasswordRequest();
  };
  return (
    <div
      className="set-password-modal-layout"
      onClick={(e) => {
        e.stopPropagation();
      }}
    >
      <div className="set-password-modal-title-container">
        {/* <div>
          <h4>We've assigned you a username!</h4>
          <h3> Welcome to Newsie: {username}</h3>
        </div>
        <div style={{marginLeft: '10%', marginRight: '10%'}}>
          If you want you can pick a different one, or you can always change it
          later
        </div>

        <UserInfo usernameInputLabel="" /> */}
        <h3>Don't loose access to your account!</h3>
        <h4>Set a password! It should:</h4>
        <ul className="password-requirements-list">
          <li>
            Contain at least <b>8</b> characters
          </li>
          <li>
            At least one <b>lowercase</b> letter
          </li>
          <li>
            At least one <b>uppercase</b> letter{" "}
          </li>
          <li>
            At least one <b>digit</b>
          </li>
          <li>
            At least one <b> character: </b><br/>!@#$%^&*()_+-={"{}"}:"{"<>"}
            ?,.\/;'\\{" "}
          </li>
        </ul>
        <div className="reset-password-container">
          <fieldset className="email-fieldset" type="password">
            <label className="email-input-label" htmlFor="password">
              Password
            </label>
            <input
              className="email-input"
              id="password"
              type="password"
              value={password}
              onChange={handlePasswordChange}
            />
          </fieldset>
          <fieldset className="email-fieldset" type="password">
            <label className="email-input-label" htmlFor="verify-password">
              Verify Password
            </label>
            <input
              className="email-input"
              id="verify-password"
              type="password"
              value={verifyPassword}
              onChange={handleVerifyPasswordChange}
            />
          </fieldset>

          <button
            className="login-button"
            disabled={!canSubmitResetPasswordRequest}
            onClick={handleResetPassword}
          >
            <b>Set Password</b>
          </button>
        </div>
      </div>
    </div>
  );
};

export default SetPassword;

import React, { useState, useEffect } from "react";

import { useDispatch, useReucer, useSelector } from "react-redux";

import {
  incrementTimer,
  startTimer,
  stopTimer,
} from "../../../../redux/features/gameSlice";

import "./Stopwatch.css";

const Stopwatch = () => {
  const dispatch = useDispatch();
  const selectedNewsie = useSelector(
    (state) => state.gameReducer.selectedNewsie
  );
  const activeModal = useSelector((state) => state.modalReducer.activeModal);

  const { time, timer_active } = useSelector((state) =>
    state.gameReducer.timer && state.gameReducer.timer[selectedNewsie]
      ? state.gameReducer.timer[selectedNewsie]
      : { time: -1, timer_active: false }
  );
  const winner = useSelector(
    (state) => state.gameReducer.winner[selectedNewsie]
  );
  useEffect(() => {
    
  }, [activeModal, winner]);

  useEffect(() => {
    let timer;
    if (!timer_active && !winner && !activeModal) dispatch(startTimer());
    if (activeModal) {
      dispatch(stopTimer());
    } else if (!winner) {
      dispatch(startTimer());
    }
    
    if (timer_active && !activeModal && !winner) {
      timer = setInterval(() => {
        dispatch(incrementTimer());
      }, 1000);
    }
    return () => {
      clearInterval(timer);
    };
  }, [timer_active, activeModal]);

  const minutes = Math.floor(time / 60);
  const seconds = time % 60 < 10 ? `0${time % 60}` : time % 60;

  return (
    <div className="stop-watch-container">
      <div className="stop-watch-heading">Time Elapsed</div>
      <div className="stop-watch">
        {minutes}:{seconds}
      </div>
    </div>
  );
};

export default Stopwatch;

import { CONFIG } from '../util/constants';

const { SERVER_API } = CONFIG;

export const recordGame = async (authToken, browserId, gamedata) => {
  let res;
  try {
    res = await fetch(`${SERVER_API}/newsie/completed`, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        "x-auth-token": authToken,
        'x-browser-id': browserId
      },
      body: JSON.stringify(gamedata),
    })
  } catch (err) {
    console.log('Error recording Game!', { err })
  }
  return await res.json();
}
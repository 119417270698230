import { createSlice } from "@reduxjs/toolkit";
import { LOCAL_STORAGE_KEYS, REQUEST_LIFE_CYCLE} from "../../util/constants";

const initialState = {
  // Request meta
  profileRequestStatus: null,
  // Account Info
  name: null,
  email: null,
  username: null,
  // username - request
  usernameRequestStatus: null,
  usernameCandidate: null,
  isEmailVerified: null,
  isGoogleConnected: null,
  isTwitterConnected: null,
  isFacebookConnected: null,
  hasSubscription: null,
  // Performance stats ... fill out .. these can change coneptually so reload
  createdDate: null,
  longestStreak: null,
};
export const profileSlice = createSlice({
  name: LOCAL_STORAGE_KEYS.PROFILE,
  initialState,
  reducers: {
    fetchProfileStart: (state) => {
      state.profileRequestStatus = "start";
    },
    fetchProfileInProgress: (state) => {
      state.profileRequestStatus = "in_progress";
    },
    fetchProfileSuccess: (state, action) => {
      state.profileRequestStatus = "success";
      const { name, email, username, creationDate, hasSubscription } =
        action.payload;
      state.name = name;
      state.email = email;
      state.username = username;
      state.createdDate = creationDate;
      state.hasSubscription = hasSubscription;
    },
    fetchProfileError: (state) => {
      state.profileRequestStatus = "success";
    },
    clearFetchProfile: (state) => {
      state.profileRequestStatus = null;
    },
    resetProfileState: () => {
      return { ...initialState };
    },
    // Claim Username actions
    startClaimUsername: (state, action) => {
      if (!state.usernameRequestStatus) {
        state.usernameRequestStatus = REQUEST_LIFE_CYCLE.START;
        state.usernameCandidate = action.payload;
      }
    },

    attemptClaimUsername: (state) => {
      state.usernameRequestStatus = REQUEST_LIFE_CYCLE.PENDING;
    },
    attemptClaimUsernameSuccess: (state) => {
      state.usernameRequestStatus = REQUEST_LIFE_CYCLE.SUCCESS;
      state.username = state.usernameCandidate;
      state.usernameCandidate = null;
    },
    attemptClaimUsernameFailureTaken: (state) => {
      state.usernameRequestStatus = "TAKEN";
    },
    attemptClaimUsernameFailureError: (state) => {
      state.usernameRequestStatus = REQUEST_LIFE_CYCLE.ERROR;
    },
    clearClaimUsernameRequest: (state) => {
      state.usernameRequestStatus = null;
      state.usernameCandidate = null;
    },
  },
});

// Action creators are generated for each case reducer function
export const {
  fetchProfileStart,
  fetchProfileInProgress,
  fetchProfileSuccess,
  fetchProfileError,
  clearFetchProfile,
  resetProfileState,
  startClaimUsername,
  attemptClaimUsername,
  attemptClaimUsernameSuccess,
  attemptClaimUsernameFailureError,
  attemptClaimUsernameFailureTaken,
  clearClaimUsernameRequest,
} = profileSlice.actions;

export default profileSlice.reducer;

import { createSlice } from "@reduxjs/toolkit";
import { LOCAL_STORAGE_KEYS } from "../../util/constants";

const initialState = {
  lastCompletedNewsie: null,
  currentStreak: 0,
  longestStreak: 0,
  achievements: [],
  categoryResultMap: {},
  perfectNewsies: 0,
  avgCompletionTime: null,
  avgMissedGuesses: null
};
export const userPerformanceSlice = createSlice({
  name: LOCAL_STORAGE_KEYS.USER_PERFORMANCE,
  initialState,
  reducers: {
    setLastCompletedNewsie: (state, action) => {
      const date = action.payload;
      state.lastCompletedNewsie = date;
    },
    setCurrentStreak: (state, action) => {
      const currentStreak = action.payload;
      state.currentStreak = currentStreak;
      if (currentStreak > state.longestStreak) {
        state.longestStreak = currentStreak;
      }
    },
    giveAchievement: (state, action) => {
      state.achievements = [...state.achievements, action.payload];
    },
    setCategoryPerformance: (state, action) => {
      const { category, categoryPerformance } = action.payload;
      const newCategoryResultMap = {
        ...state.categoryResultMap,
        [category]: categoryPerformance,
      };
      state.categoryResultMap = newCategoryResultMap;
    },
    setUserPerformance: (state, action) => {
      // Todo Add datecheck
      const newUserPerformance = action.payload;
      return { ...newUserPerformance };
    },
    resetUserPerformance: (state, action) => {
      return { ...initialState };
    },
    perfectNewsieCompleted: (state) => {
      state.perfectNewsies += 1;
    },
  },
});

// Additional logic for saving and loading from localStorage
export const loadUserPerformanceFromLocalStorage = () => {
  const storedUserPerformance = localStorage.getItem(
    LOCAL_STORAGE_KEYS.USER_PERFORMANCE
  );
  return storedUserPerformance
    ? JSON.parse(storedUserPerformance)
    : userPerformanceSlice.initialState;
};

export const saveUserPerformanceToLocalStorage = (userPerformance) => {
  localStorage.setItem(
    LOCAL_STORAGE_KEYS.USER_PERFORMANCE,
    JSON.stringify(userPerformance)
  );
};

// Action creators are generated for each case reducer function
export const {
  resetUserPerformance,
  setUserPerformance,
  setLastCompletedNewsie,
  setCurrentStreak,
  giveAchievement,
  setCategoryPerformance,
  perfectNewsieCompleted,
} = userPerformanceSlice.actions;

export default userPerformanceSlice.reducer;

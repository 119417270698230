import React, { useCallback, useRef, useEffect, useState} from "react";
import logo from "../../../Assets/newsie-triumphant-192-removebg.png"; // Tell webpack this JS file uses this image

import { useDispatch, useSelector } from "react-redux";

import { startGame } from "../../../redux/features/authSlice";
import "./LandingPageContainer.css";
import AdSenseAd from "../../AdSenseAd";
import LandingPageHeader from "./LandingPageHeader";
import Confetti from "react-confetti";

import './LandingPageContainer.css'

const LandingPageContainer = (props) => {
  const dispatch = useDispatch();
  const className = `landing-page-container`;
  const {
    date,
    handleClickHowToPlay,
    handleClickLoginButton,
    dismissModal,
    isLoggedIn,
  } = props;

  const ref = useRef(null);
  const pageContainerRef =  useRef(null);
  const handleClickPlayButton = () => {
    dispatch(startGame());
  };

  const getDateString = useCallback((date) => {
    const dayOfTheWeek = new Date(date).toLocaleString("en", {
      timeZone: "UTC",
      weekday: "long",
    });
    const day = new Date(date).getUTCDate();
    const month = new Date(date).toLocaleString("default", {
      timeZone: "UTC",
      month: "long",
    });
    const year = new Date(date).getUTCFullYear();
    return `${dayOfTheWeek}, ${month} ${day}, ${year}`;
  });


  const [height, setHeight] = useState(0);
  const [width, setWidth] = useState(0);
  const [left, setLeft] = useState(0);

  useEffect(() => {
    if (ref.current) {
      setHeight(pageContainerRef.current.clientHeight);
      setWidth(ref.current.clientWidth);
    }
    if (pageContainerRef.current) {
      setLeft(pageContainerRef.current.getBoundingClientRect().left);
    }
  }, [ref.current, pageContainerRef.current]);

  return (
    <div ref={pageContainerRef} className={className} onClick={() => dismissModal()}>
      <div className="landing-page-newsie-icon">
        <h4>{date ? getDateString(date) : "..."}</h4>
      </div>
      <div ref={ref} className="landing-page-newsie-icon">
        <div className="confetti-container" style={{left}}>
          <Confetti
            numberOfPieces={50}
            width={width}
            height={height}
            opacity={0.3}
          />
        </div>

        <div>
          <div className="newsie-logo-container-email-collection">
            <img src={logo} width={120} height={120} alt="Logo" />
          </div>
        </div>
        <div className="email-collection-title-container">
          <h1>Meet NEWSIE!</h1>
          <p>Daily Newsletter & News Quiz</p>
        </div>
      </div>
      <div className="landing-page-newsie-title">
        <h1>NEWSIE</h1>
      </div>
      <div className="landing-page-newsie-description">
        Daily Newsletter & News Quiz
      </div>
      <div className="landing-page-button-container">
        <div className="landing-page-how-to-play-button-container">
          <div
            className="landing-page-how-to-play-button"
            onClick={(e) => {
              e.stopPropagation();
              handleClickHowToPlay();
            }}
          >
            How to play
          </div>
        </div>
        <div className="landing-page-play-button-container">
          <div
            className="landing-page-play-button"
            onClick={handleClickPlayButton}
          >
            Play{isLoggedIn ? "" : " as a Guest"}
          </div>
        </div>
        {isLoggedIn ? (
          <></>
        ) : (
          <>
            or
            <div className="landing-page-login-button-container">
              <div
                className="landing-page-login-button"
                onClick={(e) => {
                  e.stopPropagation();
                  handleClickLoginButton();
                }}
              >
                Login
              </div>
            </div>
          </>
        )}
      </div>
    </div>
  );
};

export default LandingPageContainer;

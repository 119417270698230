import React from "react";

import "./LeaderBoardRow.css";

const LeaderBoardRow = (props) => {
  const { username, score } = props.data;
  const { index } = props;

  return (
    <div className='leader-board-table-row-container'>
      <div className="leader-board-row-container">
      <div className="leader-board-row-username-container">
        <div className='leader-board-row-number-container'>
          <h4>{index + 1}.</h4>
        </div>{" "}
        {username}
      </div>
      <div className="leader-board-row-score-container">
        {score}
      </div>
    </div>
    </div>
  );
};

export default LeaderBoardRow;

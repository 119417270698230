import { configureStore } from '@reduxjs/toolkit'
import gameReducer, { loadGameFromLocalStorage, saveGameToLocalStorage} from './features/gameSlice';
import userPerformanceReducer, { loadUserPerformanceFromLocalStorage, saveUserPerformanceToLocalStorage} from './features/userPerformanceSlice';

import authReducer, { loadAuthFromLocalStorage, saveAuthToLocalStorage } from './features/authSlice'
import modalReducer, { loadModalFromLocalStorage, saveModalToLocalStorage } from './features/modalSlice'
import profileReducer from './features/profileSlice';
import metaReducer from './features/metaSlice';

const preloadedState = {
  gameReducer: loadGameFromLocalStorage(),
  authReducer: loadAuthFromLocalStorage(),
  modalReducer: loadModalFromLocalStorage(),
  userPerformanceReducer: loadUserPerformanceFromLocalStorage(),
};

const store = configureStore({
  reducer: {
    gameReducer,
    userPerformanceReducer,
    authReducer,
    modalReducer,
    profileReducer,
    metaReducer,
  },
  preloadedState
});
store.subscribe(() =>  {
  const gameState = store.getState().gameReducer;
  saveGameToLocalStorage(gameState)
  let authState = store.getState().authReducer;
  authState = {...authState, clickedPlay: false}
  saveAuthToLocalStorage(authState);
  let modalState = store.getState().modalReducer;
  saveModalToLocalStorage(modalState);
  let userPerformanceState = store.getState().userPerformanceReducer
  saveUserPerformanceToLocalStorage(userPerformanceState);
})

export default store;
import React from "react";

import { useSelector, useDispatch } from "react-redux";

import HowToPlay from "./modals/HowToPlay";
import Login from "./modals/Login";
import Winner from "./modals/Winner";
import HamburgerMenu from "./modals/HamburgerMenu";
import NewsieCategories from "./modals/NewsieCategories";
import ConfirmationModal from "./modals/ConfirmationModal";
import GetNewsiePlus from "./modals/GetNewsiePlus";
import SetPassword from "./modals/SetPassword";
import UpdateUsername from "./modals/UpdateUsername";

import "./Modal.css";
import { dismissModal } from "../../redux/features/modalSlice";

// Modals
const HOW_TO_PLAY = "HOW_TO_PLAY";
const LOGIN = "LOGIN";
const WINNER = "WINNER";
const HAMBURGER_MENU = "HAMBURGER_MENU";
const NEWSIE_CATEGORIES = "NEWSIE_CATEGORIES";
const CONFIRMATION_MODAL = "CONFIRMATION_MODAL";
const GET_NEWSIE_PLUS = "GET_NEWSIE_PLUS";
const SET_PASSWORD = "SET_PASSWORD";
const UPDATE_USERNAME = "UPDATE_USERNAME";

const CONFIRM_USER_DELETE = "CONFIRM_USER_DELETE";

export const CONFIRMATION_MODAL_VARIANTS = {
  [CONFIRM_USER_DELETE]: CONFIRM_USER_DELETE,
};

export const MODALS = {
  HOW_TO_PLAY: HOW_TO_PLAY,
  LOGIN: LOGIN,
  WINNER: WINNER,
  HAMBURGER_MENU: HAMBURGER_MENU,
  NEWSIE_CATEGORIES: NEWSIE_CATEGORIES,
  CONFIRMATION_MODAL: CONFIRMATION_MODAL,
  GET_NEWSIE_PLUS: GET_NEWSIE_PLUS,
  SET_PASSWORD: SET_PASSWORD,
  UPDATE_USERNAME: UPDATE_USERNAME,
};

const modalToCssMap = {
  NEWSIE_CATEGORIES: "newsie-categories",
  HOW_TO_PLAY: "how-to-play",
  LOGIN: "login",
  WINNER: "winner",
  HAMBURGER_MENU: "hamburger-menu",
  CONFIRMATION_MODAL: "confirmation-modal",
  GET_NEWSIE_PLUS: "get-newsie-plus",
  SET_PASSWORD: "set-password",
  UPDATE_USERNAME: "update-username",
};

const modalToComponentMap = {
  [HOW_TO_PLAY]: () => <HowToPlay skipExitButton />,
  [LOGIN]: () => <Login />,
  [WINNER]: () => <Winner />,
  [HAMBURGER_MENU]: () => <HamburgerMenu />,
  [NEWSIE_CATEGORIES]: () => <NewsieCategories />,
  [CONFIRMATION_MODAL]: () => <ConfirmationModal />,
  [GET_NEWSIE_PLUS]: () => <GetNewsiePlus />,
  [SET_PASSWORD]: () => <SetPassword />,
  [UPDATE_USERNAME]: () => <UpdateUsername />,
};

const Modal = (props) => {
  const dispatch = useDispatch();
  const variant = useSelector(
    (state) => state.modalReducer.dynamicModalVariant
  );
  const {
    shouldShowModal,
    modal,
    shouldDismissModal,
    onDismissModal,
    userDeletionRequest,
  } = props;
  const className = `Modal ${
    shouldShowModal && !shouldDismissModal ? "show" : ""
  } ${modalToCssMap[modal]} ${shouldDismissModal ? "disappear" : ""}`;
  const ModalContent = modalToComponentMap[modal];

  const handleClickCloseModalX = (e) => {
    e.stopPropagation();
    dispatch(dismissModal());
  };

  const backgroundClassName = `${
    shouldShowModal && !shouldDismissModal ? "bg" : ""
  }`;
  return (
    <div className={backgroundClassName} onClick={onDismissModal}>
      <div className={className}>
        {modal !== "HOW_TO_PLAY" && (
          <div className="modal-x-container" onClick={handleClickCloseModalX}>
            X
          </div>
        )}
        {ModalContent && <ModalContent />}
      </div>
    </div>
  );
};

export default Modal;

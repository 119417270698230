import React from "react";

import "./WordCard.css";

import { useDispatch } from "react-redux";

import { selectWord } from "../../../redux/features/gameSlice";

const WordCard = (props) => {
  const dispatch = useDispatch();

  const {
    infoModal = false,
    isSelected,
    word,
    columnIndex,
    wordIndex,
    incorrect,
    correct,
  } = props;
  const handleClickCard = () => {
    const wordVal = !isSelected ? wordIndex : null;
    if (correct) return;
    dispatch(selectWord({ columnIndex, wordIndex: wordVal, fromTap: true }));
  };
  const doingNothing = !correct && !incorrect;
  const className = `word-card ${isSelected ? "selected" : ""} ${
    incorrect && isSelected ? "incorrect" : ""
  } ${correct && isSelected ? "correct" : ""} ${
    word.length > 8 ? "longword" : ""
  } ${word.length > 10 ? "very-longword" : ""} ${
    doingNothing ? "doing-nothing" : ""
  } `;
  return (
    <div className={className} onClick={handleClickCard}>
      <b>{word}</b>
    </div>
  );
};

export default WordCard;

import { useCallback, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { setStories } from "../../redux/features/gameSlice";

import "./LocalEdit.css";

import storiesJson from "./result.js";
const LocalEdit = () => {
  const [word1, setWord1] = useState();
  const [word2, setWord2] = useState();
  const [word3, setWord3] = useState();
  const [articleTitle, setArticleTitle] = useState();
  const [emoji, setEmoji] = useState();

  const dispatch = useDispatch();

  const selectedNewsie = useSelector(
    (state) => state.gameReducer.selectedNewsie
  );

  const stories = useSelector(
    (state) => state.gameReducer.stories[selectedNewsie]
  );
  const solvedStories = Object.keys(stories);

  const handleAddNewsie = useCallback(() => {
    const newStories = storiesJson.resultMap;
    newStories["top-newsie"].shift();
    newStories["top-newsie"] = [
      {
        inNewsletter: true,
        date: "top-newsie-2024-9-5",
        emoji,
        clue: "Government takes control of foreign online platforms related to political interference",
        rundown:
          "US confiscates Russian sites linked to 2024 election interference.",
        newsieHeadline: `${word1} ${word2} ${word3}`,
        summaryBullets: [],
        category: "politics",
        link: "https://thehill.com/homenews/campaign/4861766-biden-administration-condemns-russian-influence/",
        id: "US Website Seizure-2024-9-4",
        title: articleTitle,
      },
      ,
      ...newStories["top-newsie"],
    ];
    dispatch(setStories(newStories));
  }, [dispatch, storiesJson, word1, word2, word3, articleTitle, emoji]);

  return (
    <div className="local-edit-container">
      <div>
        <div>Word1</div>
        <input
          type="text"
          onChange={(e) => setWord1(e.target.value)}
          value={word1}
        />
      </div>
      <div>
        <div>Word2</div>
        <input
          type="text"
          onChange={(e) => setWord2(e.target.value)}
          value={word2}
        />
      </div>
      <div>
        <div>Word3</div>
        <input
          type="text"
          onChange={(e) => setWord3(e.target.value)}
          value={word3}
        />
      </div>
      <div>
        <div>Article Title</div>
        <input
          type="text"
          onChange={(e) => setArticleTitle(e.target.value)}
          value={articleTitle}
        />
      </div>
      <div>
        <div>Emoji</div>
        <input
          type="text"
          onChange={(e) => setEmoji(e.target.value)}
          value={emoji}
        />
      </div>
      <div className="add-newsie-locally-button" onClick={handleAddNewsie}>
        Add Newsie Locally
      </div>
      <div className="story-table">
        {solvedStories.map((s) => (
          <div>
            {" "}
            {s} --- {stories[s].emoji}{" "}
          </div>
        ))}
      </div>
    </div>
  );
};

export default LocalEdit;

import React from 'react';

import './MenuItem.css';

const MenuItem = (props) => {
  const { itemTitle, onClickMenuItem } = props;

  return (<div className='hamburger-menu-item' onClick={onClickMenuItem}>
    {itemTitle}
  </div>);
}

export default MenuItem;
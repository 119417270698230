import React, { useMemo } from "react";

import { useSelector } from "react-redux";

import MenuItem from "../../MenuItem";

import "./Default.css";
import { useNavigate } from "react-router-dom";

import logo from "../../../../../../Assets/newsie-triumphant-192-removebg.png"; // Tell webpack this JS file uses this image

import { CONFIG } from "../../../../../../util/constants";
import Confetti from "react-confetti";
import LeaderBoard from "./LeaderBoard";
import ViewProfileButton from "./ViewProfileButton";

const Default = (props) => {
  const {
    loggedIn,
    hasSubscription,
    onClickSettings,
    onClickProfile,
    onClickLogin,
    onClickLogout,
    onClickGetNewsiePlus,
    onCickLoginToUseNewsiePlus,
    username,
  } = props;
  const navigate = useNavigate();

  const dailyTopScores = useSelector(
    (state) => state.metaReducer.dailyTopScores
  );
  const AuthAction = useMemo(
    () =>
      loggedIn
        ? () => (
            <div className="logout-button" onClick={onClickLogout}>
              <div>Logout</div>
            </div>
          )
        : () => (
            <div className="hamburger-menu-login-button" onClick={onClickLogin}>
              <div>Log in</div>
            </div>
          ),
    [loggedIn]
  );

  const SubscriptionButton = useMemo(() => {
    if (!loggedIn) {
      // Login w context to know that logging in will allow you to get +
      return () => (
        <MenuItem
          itemTitle="Log in to use Newsie +"
          onClickMenuItem={onCickLoginToUseNewsiePlus}
        />
      );
    } else if (!hasSubscription) {
      return () => (
        <MenuItem
          itemTitle="Get Newsie +"
          onClickMenuItem={onClickGetNewsiePlus}
        />
      );
    } else if (hasSubscription) {
      return () => (
        <MenuItem
          itemTitle="Manage Newsie +"
          onClickMenuItem={() => {
            window.location.href = CONFIG.REACT_APP_STRIPE_CUSTOMER_PORTAL;
          }}
        />
      );
    }
  }, [loggedIn, hasSubscription]);
  return (
    <div className="default-hamburger-screen-layout">
      <div className="default-menu-header-container">
        <Confetti
          width={100 - 200}
          height={200}
          opacity={0.4}
          initialVelocityY={5}
          recycle={true}
          gravity={0.05}
          // colors={['#1b401e']}
        />
        <div>
          <div className="newsie-logo-container-email-collection">
            <img src={logo} width={120} height={120} alt="Logo" />
          </div>
        </div>
        <div className="default-menu-thanks-container">
          <strong>Thank you for using NEWSIE!</strong>
        </div>
        <ViewProfileButton
          onClickProfile={onClickProfile}
          username={username}
        />
      </div>
      <div className="default-view-action-items-container">
        {/* <PerformanceCenter /> */}
        <LeaderBoard
          leaderBoardTitle="Today's Top Scores"
          leaderBoardData={dailyTopScores}
        />
        {/* <LeaderBoard leaderBoardTitle="This Week's Top Scores" leaderBoardData={LEADER_BOARD_DATA}/> */}
      </div>
      <div className="default-menu-spacer" />
      <div className="default-menu-items-container">
        <MenuItem itemTitle="Settings" onClickMenuItem={onClickSettings} />
        <AuthAction />
      </div>
    </div>
  );
};

export default Default;

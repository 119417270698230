import React, { useEffect, useMemo, useCallback } from "react";
import { useSelector, useDispatch } from "react-redux";
import {
  loginWithEmail,
  forgotPassword,
  sendAccountCreationEmail,
} from "./api";

import {InvalidUserInputError} from '../../../../util/Errors';

import Default from "./pages/Default";
import LoginWithEmail from "./pages/LoginWithEmail";
import LoadingScreen from "./pages/LoadingScreen";
import CreateAccountWithEmail from "./pages/CreateAccountWithEmail";
import CheckYourEmail, {
  CHECK_YOUR_EMAIL_VARIANTS,
} from "./pages/CheckYourEmail";
import Captcha from "./pages/Captcha";
import "./Login.css";
import {
  setLoginModalScreen,
  setCaptchaAction,
  setAuthError,
  closeAuthModal,
  authSuccess,
  setCaptchaToken,
} from "../../../../redux/features/authSlice";
import { AUTH_ERRORS, LOCAL_STORAGE_KEYS } from "../../../../util/constants";

import { CAPTCHA_ACTIONS } from "./pages/Captcha";

import AuthError from "./pages/Error";

export const DEFAULT = "default";
export const LOGIN_WITH_EMAIL = "login_with_email";
export const CREATE_WITH_EMAIL = "create_with_email";
export const LOADING_SCREEN = "loading_screen";
export const CHECK_YOUR_EMAIL = "check_your_email";
export const CAPTCHA = "captcha";

const AUTH_ERRORS_MESSAGE_MAP = {
  [AUTH_ERRORS.INVALID_PASSWORD]: {
    title: "Ooops... wrong password... ",
    message: "Give it another try or try resetting your password",
  },
  [AUTH_ERRORS.INVALID_USER_INPUT_ERROR]: {
    title: "Ooops... wrong password... ",
    message: "Give it another try or try resetting your password",
  },
  [AUTH_ERRORS.SERVER_ERROR]: {
    title: "Shoot! Sorry its us.",
    message:
      "Sorry about that! Try again later we might be experiencing some issues...",
  },
};
const Login = () => {
  const dispatch = useDispatch();
  const loginModalScreen = useSelector(
    (state) => state.authReducer.loginModalScreen
  );
  const email = useSelector((state) => state.authReducer.authEmail);
  const captchaAction = useSelector((state) => state.authReducer.captchaAction);
  const captchaToken = useSelector((state) => state.authReducer.captchaToken);
  const browserId = useSelector((state) => state.authReducer.browserId);
  const password = useSelector((state) => state.authReducer.authPassword);
  const loginInWithEmailInProgress = useSelector(
    (state) => state.authReducer.loginInWithEmailInProgress
  );
  const authError = useSelector((state) => state.authReducer.authError);
  const emailAccountCreationToken = useSelector(
    (state) => state.authReducer.emailAccountCreationToken
  );

  const checkYourEmailVariant = useMemo(
    () =>
      emailAccountCreationToken
        ? CHECK_YOUR_EMAIL_VARIANTS.CREATE
        : CHECK_YOUR_EMAIL_VARIANTS.RECOVERY,
    [emailAccountCreationToken]
  );

  // Unmount - clear all login
  useEffect(
    () => () => {
      dispatch(setAuthError(null));
      dispatch(closeAuthModal());
    },
    []
  );

  const handleLoginRequest = useCallback(async () => {
    if (loginInWithEmailInProgress) {
      let loginResult;
      try {
        loginResult = await loginWithEmail(email, password, browserId);
      } catch (err) {
        if (err instanceof InvalidUserInputError) {
          dispatch(setAuthError(AUTH_ERRORS.INVALID_PASSWORD));
          dispatch(setLoginModalScreen(LOGIN_WITH_EMAIL))
        } else  {
          dispatch(setAuthError(AUTH_ERRORS.SERVER_ERROR));
        }
        
        return;
      }
      const { apiToken } = loginResult;
      dispatch(authSuccess(apiToken));
      localStorage.setItem(LOCAL_STORAGE_KEYS.SESSION_TOKEN, apiToken);
    }
  }, [email, password, browserId, loginInWithEmailInProgress]);

  useEffect(() => {
    handleLoginRequest();
  }, [loginInWithEmailInProgress]);

  const processCaptchaResult = useCallback(
    async (value) => {
      if (captchaAction === CAPTCHA_ACTIONS.EMAIL_CREATE_ACCOUNT) {
        sendAccountCreationEmail(email, value, browserId);
        dispatch(setLoginModalScreen(CHECK_YOUR_EMAIL));
        dispatch(setCaptchaAction(null));
      } else if (captchaAction === CAPTCHA_ACTIONS.EMAIL_FORGOT_PASSWORD) {
        forgotPassword(email, value, browserId);
        dispatch(setLoginModalScreen(CHECK_YOUR_EMAIL));
        dispatch(setCaptchaAction(null));
      }
      setCaptchaToken(null);
    },
    [browserId, captchaToken, email]
  );

  useEffect(() => {
    if (captchaToken) processCaptchaResult(captchaToken);
  }, [captchaToken]);

  const LoginModalScreen = useMemo(() => {
    if (authError) {
      switch (authError) {
        case AUTH_ERRORS.INVALID_PASSWORD:
          return () => <LoginWithEmail invalidPassword={true} />;
        case AUTH_ERRORS.USER_INPUT_ERROR:
          return () => (
            <AuthError
              errorModalTitle={AUTH_ERRORS_MESSAGE_MAP[authError].title}
              message={AUTH_ERRORS_MESSAGE_MAP[authError].message}
            />
          );
        case AUTH_ERRORS.SERVER_ERROR:
          return () => (
            <AuthError
              errorModalTitle={AUTH_ERRORS_MESSAGE_MAP[authError].title}
              message={AUTH_ERRORS_MESSAGE_MAP[authError].message}
            />
          );
      }
    }

    switch (loginModalScreen) {
      case DEFAULT:
        return () => <Default />;
      case CREATE_WITH_EMAIL:
        return () => (
          <CreateAccountWithEmail
            emailAccountCreationToken={emailAccountCreationToken}
          />
        );
      case LOGIN_WITH_EMAIL:
        return () => <LoginWithEmail />;
      case LOADING_SCREEN:
        return () => <LoadingScreen />;
      case CHECK_YOUR_EMAIL:
        return () => <CheckYourEmail variant={checkYourEmailVariant} />;
      case CAPTCHA:
        return () => <Captcha />;
    }
  }, [
    loginModalScreen,
    Default,
    LoginWithEmail,
    LoadingScreen,
    CheckYourEmail,
    CreateAccountWithEmail,
    authError,
  ]);

  return (
    <div
      className="login"
      onClick={(e) => {
        e.stopPropagation();
      }}
    >
      {<LoginModalScreen />}
    </div>
  );
};

export default Login;

import React from "react";

import MenuItem from "../../MenuItem";

import { CONFIG } from "../../../../../../util/constants";

import "./Settings.css";

const Settings = (props) => {
  const { loggedIn, onClickDelete, onClickBack } = props;

  return (
    <div className="settings-hamburger-menu-layout">
      <div className="hamburger-menu-setting-back-header-container">
        <div className="hamburger-menu-back-button" onClick={onClickBack}>
          {`<`}Back
        </div>
      </div>
      <div className="setting-menu-items-container">
        {/* <MenuItem itemTitle='Manage Subscription' onClickMenuItem={() => { window.location.href = CONFIG.REACT_APP_STRIPE_CUSTOMER_PORTAL }} /> */}
        <MenuItem
          itemTitle="Privacy Policy"
          onClickMenuItem={() => (window.location.href = `/privacy`)}
        />
        <MenuItem
          itemTitle="Terms of Service"
          onClickMenuItem={() => (window.location.href = `/terms`)}
        />
        {loggedIn && (
          <MenuItem itemTitle="Delete" onClickMenuItem={onClickDelete} />
        )}
      </div>
    </div>
  );
};

export default Settings;

import { createSlice } from "@reduxjs/toolkit";

import { LOCAL_STORAGE_KEYS } from "../../util/constants";

import { v4 as uuidv4 } from "uuid";

const session = localStorage.getItem(LOCAL_STORAGE_KEYS.SESSION_TOKEN);
const initialState = {
  emailOtpCode: "",
  date: "",
  authEmail: "",
  authPassword: "",
  browserId: uuidv4(),
  loginModalScreen: "default",
  loginInWithEmailInProgress: false,
  loginStatus: null, // SUCCESS ERROR,
  authToken: session || "",
  refreshToken: '',
  clickedPlay: false, // Landing Page
  twitterCode: null,
  authError: null,
  deleteUserRequest: null,
  captchaAction: null,
  emailAccountCreationToken: null, // through email
  postAuthAction: null,
  postAuthActionTrigger: false,
};
export const authSlice = createSlice({
  name: LOCAL_STORAGE_KEYS.AUTH,
  initialState,
  reducers: {
    setRefreshToken: (state, action) => {
      state.refreshToken = action.payload;
    },
    setDate: (state, action) => {
      state.date = action.payload;
    },
    setEmailOtpCode: (state, action) => {
      state.emailOtpCode = action.payload;
    },
    startGame: (state, action) => {
      state.clickedPlay = true;
    },
    setEmailAccountCreateToken: (state, action) => {
      state.emailAccountCreationToken = action.payload;
    },
    setCaptchaAction: (state, action) => {
      state.captchaAction = action.payload;
    },
    setCaptchaToken: (state, action) => {
      state.captchaToken = action.payload;
    },
    setAuthEmail: (state, action) => {
      state.authEmail = action.payload;
    },
    setAuthPassword: (state, action) => {
      state.authPassword = action.payload;
    },
    setLoginModalScreen: (state, action) => {
      state.loginModalScreen = action.payload;
    },
    loginWithEmailStart: (state) => {
      state.loginInWithEmailInProgress = true;
    },
    submitTwitterCode: (state, action) => {
      state.twitterCode = action.payload;
    },
    authSuccess: (state, action) => {
      state.loginStatus = "SUCCESS";
      state.authToken = action.payload;
      state.authPassword = "";
      state.loginModalScreen = "default";
      state.loginInWithEmailInProgress = false;
      state.emailAccountCreationToken = null;
      state.postAuthActionTrigger = true;
    },
    logout: (state) => {
      state.clickedPlay = false;
      state.loginStatus = null;
      state.authToken = '';
      state.refreshToken = '';
      state.authEmail = '';
      state.loginModalScreen = 'default';
      state.deleteUserRequest = null;
      localStorage.removeItem(LOCAL_STORAGE_KEYS.SESSION_TOKEN);
    },
    setAuthError: (state, action) => {
      state.authError = action.payload;
      state.loginInWithEmailInProgress = false;
    },
    closeAuthModal: (state) => {
      state.authError = null;
      state.authPassword = null;
      state.authEmail = null;
      state.loginModalScreen = "default";
    },
    startDeleteAccount: (state) => {
      state.deleteUserRequest = "start";
    },
    deleteAccountInProgress: (state) => {
      state.deleteUserRequest = "in_progress";
    },
    deleteAccountSuccess: (state) => {
      state.deleteUserRequest = "success";
    },
    deleteAccountError: (state) => {
      state.deleteUserRequest = "error";
    },
    clearDeleteAccountStatus: (state) => {
      state.deleteUserRequest = null;
    },
    setPostAuthAction: (state, action) => {
      state.postAuthAction = action.payload;
    },
    completedPostAuthAction: (state) => {
      state.postAuthActionTrigger = false;
    },
  },
});

// Additional logic for saving and loading from localStorage
export const loadAuthFromLocalStorage = () => {
  const storedAuth = localStorage.getItem(LOCAL_STORAGE_KEYS.AUTH);
  const browserId = localStorage.getItem(LOCAL_STORAGE_KEYS.BROWSER_ID);
  if (!storedAuth) return authSlice.initialState;
  const newAuth = JSON.parse(storedAuth);
  newAuth.browserId = browserId;
  newAuth.clickedPlay = false;
  newAuth.loginStatus = null;
  return newAuth;
};

export const saveAuthToLocalStorage = (auth) => {
  const authSlice = { ...auth, date: null };
  localStorage.setItem(LOCAL_STORAGE_KEYS.AUTH, JSON.stringify(authSlice));
  localStorage.setItem(LOCAL_STORAGE_KEYS.BROWSER_ID, auth.browserId);
};

// Action creators are generated for each case reducer function
export const {
  startGame,
  setDate,
  setRefreshToken,
  setAuthEmail,
  setEmailOtpCode,
  setCaptchaAction,
  setCaptchaToken,
  setLoginModalScreen,
  setEmailAccountCreateToken,
  setAuthPassword,
  authSuccess,
  logout,
  loginWithEmailStart,
  setAuthError,
  closeAuthModal,
  startDeleteAccount,
  deleteAccountError,
  deleteAccountSuccess,
  clearDeleteAccountStatus,
  deleteAccountInProgress,
  setPostAuthAction,
  completedPostAuthAction,
} = authSlice.actions;

export default authSlice.reducer;

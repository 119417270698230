import React, { useMemo } from "react";

import NewsieMascot from "../../NewsieMascot";
import MenuItem from "../../MenuItem";

import "./Profile.css";
import { useNavigate } from "react-router-dom";

import { CONFIG } from "../../../../../../util/constants";

import PerformanceCenter from "./PerformanceCenter";
import UserInfo from "./UserInfo";

const Profile = (props) => {
  const {
    loggedIn,
    hasSubscription,
    onClickGetNewsiePlus,
    onCickLoginToUseNewsiePlus,
  } = props;
  const navigate = useNavigate();

  const SubscriptionButton = useMemo(() => {
    if (!loggedIn) {
      // Login w context to know that logging in will allow you to get +
      return () => (
        <MenuItem
          itemTitle="Log in to use Newsie +"
          onClickMenuItem={onCickLoginToUseNewsiePlus}
        />
      );
    } else if (!hasSubscription) {
      return () => (
        <MenuItem
          itemTitle="Get Newsie +"
          onClickMenuItem={onClickGetNewsiePlus}
        />
      );
    } else if (hasSubscription) {
      return () => (
        <MenuItem
          itemTitle="Manage Newsie +"
          onClickMenuItem={() => {
            window.location.href = CONFIG.REACT_APP_STRIPE_CUSTOMER_PORTAL;
          }}
        />
      );
    }
  }, [loggedIn, hasSubscription]);
  return (
    <div className="profile-screen-layout" onClick={(e) => e.stopPropagation()}>
      <div className="profile-header-container">
        <NewsieMascot />
         <UserInfo usernameInputLabel="Username:"/>
      </div>
      <div className="default-view-action-items-container">
        <PerformanceCenter />
      </div>
    </div>
  );
};

export default Profile;

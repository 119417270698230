import React from "react";

import { useDispatch, useSelector } from "react-redux";

import { setPostAuthAction } from "../../../../redux/features/authSlice";
import { setNewsie } from "../../../../redux/features/gameSlice";

import { NEWSIE_CATEGORIES } from "../../../../util/constants";

import "./NewsieCategories.css";
import { setAd, setModal } from "../../../../redux/features/modalSlice";

import NewsieCategoryButton from "./NewsieCategoryButton";
import { MODALS } from "../..";

const NewsieCategories = () => {
  const allNewsies = useSelector((state) => state.gameReducer.allNewsies);
  const authToken = useSelector((state) => state.authReducer.authToken);
  const hasSubscription = useSelector((state) => state.profileReducer.hasSubscription);

  const dispatch = useDispatch();
  const handleNewsieButtonClick = (category) => {
    dispatch(setAd());
    dispatch(setNewsie(category));
  };

  const handleUpgradeToNewsiePlusClick = (e) => {
    e.stopPropagation();
    if (authToken) {
      dispatch(setModal(MODALS.GET_NEWSIE_PLUS));
    } else {
      dispatch(setPostAuthAction("plus"));
      dispatch(setModal(MODALS.LOGIN));
    }
  };

  return (
    <div className="newsie-categories-modal-content">
      <div className="newsie-categories">
        <div className="newsie-category-row">
          {NEWSIE_CATEGORIES.map((c, i) => (
            <NewsieCategoryButton
              key={i}
              loggedIn={Boolean(authToken)}
              category={c}
              onClick={handleNewsieButtonClick}
              enabled={Boolean(allNewsies[c])}
              isPng={c === "top-newsie"}
            />
          ))}
        </div>
      </div>
      {!hasSubscription && <div
        className="upgrade-to-newsie-plus-cta-container"
        onClick={handleUpgradeToNewsiePlusClick}
      >
        Get daily access to all categories by upgrading to NEWSIE+
        today!
      </div>}
    </div>
  );
};

export default NewsieCategories;

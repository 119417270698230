import React from 'react';
import { useDispatch } from 'react-redux';

import { setCaptchaToken } from '../../../../../../redux/features/authSlice'
import ReCAPTCHA from "react-google-recaptcha";

const EMAIL_CREATE_ACCOUNT = 'EMAIL_CREATE_ACCOUNT'
const EMAIL_FORGOT_PASSWORD = 'EMAIL_FORGOT_PASSWORD'

export const CAPTCHA_ACTIONS = {
  [EMAIL_CREATE_ACCOUNT]: EMAIL_CREATE_ACCOUNT,
  [EMAIL_FORGOT_PASSWORD]: EMAIL_FORGOT_PASSWORD,
};

export const Captcha = (props) => {
  const dispatch = useDispatch();

  function onChange(value) {
    dispatch(setCaptchaToken(value))
  }


  return (<div className='login-captcha-container'>
    <ReCAPTCHA
      sitekey="6LcyYl8oAAAAAJSnNXVCewYkYW0SVYEKj-Pgjqfa"
      onChange={onChange}
    />
  </div>)
}

export default Captcha;
import { createSlice } from '@reduxjs/toolkit';
import { LOCAL_STORAGE_KEYS } from '../../util/constants';

const initialState = {
  prices: null,
  dailyTopScores: null
};
export const metaSlice = createSlice({
  name: LOCAL_STORAGE_KEYS.META,
  initialState,
  reducers: {
    setPrices: (state, action) => {
      state.prices = action.payload;
    },
    setDailyTopScores: (state, action) => {
      state.dailyTopScores = action.payload;
    }
  }
})

// Action creators are generated for each case reducer function
export const { 
  setPrices,
  setDailyTopScores
 } = metaSlice.actions

export default metaSlice.reducer
import React from 'react';
import logo from '../../../../../../Assets/sad-newsie-192-removebg.png'


import './Error.css';
const AuthError = (props) => {
  const { errorModalTitle, message } = props;
  return (<div className='error-modal'>
    <div className="auth-error-modal-title-container"><h2>{errorModalTitle}</h2></div>
    <div className='auth-error-sad-newsie-container'>
      <img src={logo} alt="Logo" />
    </div>
    <div className='auth-error-modal-message-container'>{message}</div>
  </div>)

};

export default AuthError;
import { useState } from 'react';
import './StoryContainer.css';

import CorrectWords from '../CorrectWords';

const StoryContainer = (props) => {
  const [isOpen, setIsOpen] = useState(false);
  const { story, showTitle } = props;
  const { wrong } = story;
  const handleClickStory = () => {
    setIsOpen(!isOpen)
  };

  const OpenStoryContent = (props) => {
    const {story} = props;
    // if (story.description) return (
    //   <p><b>Highlights: </b>{story.description}<br /><b><a target="_blank" href={story.link}>Read More...</a></b></p>
    // );
    return (
      <p><b><a target="_blank" href={story.link}>Read More...</a></b></p>)

  }
  const className = `story-container ${isOpen ? 'open' : ''} ${wrong ? 'wrong' : ''}`
  return (<div className={className} onClick={handleClickStory}>
    <CorrectWords words={story.headLineArray} />
    {(showTitle || isOpen) && <b>{story.emoji + ` ${story.title}`}</b>}
    {isOpen && <OpenStoryContent story={story}/>}
  </div>)
};

export default StoryContainer;
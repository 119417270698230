const stories = {
  "resultMap": {
      "top-newsie": [
          {
              "inNewsletter": true,
              "date": "top-newsie-2024-9-5",
              "emoji": "🕵♂",
              "clue": "Government takes control of foreign online platforms related to political interference",
              "rundown": "US confiscates Russian sites linked to 2024 election interference.",
              "newsieHeadline": "US BONER Seizure",
              "summaryBullets": [
                  "US condemns Russian efforts to influence 2024 election",
                  "Justice Department seizes 32 web domains used in covert campaigns"
              ],
              "category": "politics",
              "link": "https://thehill.com/homenews/campaign/4861766-biden-administration-condemns-russian-influence/",
              "id": "US Website Seizure-2024-9-4",
              "title": "US seizes Russian websites used in bid to influence 2024 election"
          },
          {
              "inNewsletter": true,
              "date": "top-newsie-2024-9-5",
              "emoji": "💨",
              "clue": "Pitcher achieves recordbreaking fastball speed",
              "rundown": "Angels rookie Ben Joyce sets record with 105.5 mph pitch.",
              "newsieHeadline": "Record Breaking Pitch",
              "summaryBullets": [],
              "category": "sports",
              "link": "https://v.redd.it/11qzvwtd8tmd1",
              "id": "Record Breaking Pitch-2024-9-4",
              "title": "Angels rookie Ben Joyce throws fastest recorded strikeout pitch, 105.5 mph, in pitch-tracking era"
          },
          {
              "inNewsletter": true,
              "date": "top-newsie-2024-9-5",
              "emoji": "🕵♂",
              "clue": "Legal process against Hollywood figure",
              "rundown": "New evidence against Harvey Weinstein reviewed by NY jury.",
              "newsieHeadline": "Weinstein New Evidence",
              "summaryBullets": [
                  "New evidence against Harvey Weinstein being considered by grand jury",
                  "Weinstein's attorney ready for potential new charges"
              ],
              "category": "entertainment",
              "link": "https://www.nbcnews.com/news/us-news/new-york-grand-jury-weighing-new-evidence-harvey-weinstein-source-says-rcna169550",
              "id": "Weinstein New Evidence-2024-9-4",
              "title": "A New York grand jury is weighing new evidence against Harvey Weinstein, a source says "
          },
          {
              "inNewsletter": true,
              "date": "top-newsie-2024-9-5",
              "emoji": "🧠",
              "clue": "Toxic chemicals impact brain development",
              "rundown": "PFAS chemicals linked to various health issues, brain development.",
              "newsieHeadline": "PFAS Brain Effects",
              "summaryBullets": [],
              "category": "science",
              "link": "https://www.ufz.de/index.php?en=36336&amp;webc_pm=33/2024",
              "id": "PFAS Brain Effects-2024-9-4",
              "title": "PFAS “forever chemicals” can lead to liver damage, obesity, hormonal disorders, and cancer, and a new study finds they also influence the development and function of the brain. A new study reveals mechanism of action and genes involved using a zebrafish model. These genes are also present in humans."
          },
          {
              "inNewsletter": true,
              "date": "top-newsie-2024-9-5",
              "emoji": "💰",
              "clue": "Big lottery prize grows",
              "rundown": "September 3 Mega Millions drawing: Jackpot hits $681M.",
              "newsieHeadline": "Mega Millions Jackpot",
              "summaryBullets": [
                  "Mega Millions jackpot climbs to a whopping $681 million after Tuesday's drawing",
                  "Two people have already won Mega Millions jackpots this year, with the jackpot now at $740 million for Friday's drawing"
              ],
              "category": "business",
              "link": "https://www.usatoday.com/story/money/lottery/2024/09/03/mega-millions-winning-numbers/75064778007/",
              "description": "Mega Millions players, the results are in for Tuesday, Sept. 3. Here are the winning numbers for the drawing.",
              "id": "Mega Millions Jackpot-2024-9-4",
              "title": "Mega Millions winning numbers for September 3 drawing; Jackpot climbs to $681 million - USA TODAY"
          },
          {
              "inNewsletter": true,
              "date": "top-newsie-2024-9-5",
              "emoji": "💉",
              "clue": "Antibody shields against diverse viruses",
              "rundown": "Antibody found to guard against all COVID variants.",
              "newsieHeadline": "COVID Variant Protection",
              "summaryBullets": [],
              "category": "uplifting",
              "link": "https://www.cell.com/cell-reports-medicine/fulltext/S2666-3791(24)00382-3",
              "id": "COVID Variant Protection-2024-9-4",
              "title": "Newly discovered antibody protects against all known COVID variants"
          },
          {
              "inNewsletter": true,
              "date": "top-newsie-2024-9-5",
              "emoji": "🇷🇺",
              "clue": "US imposes penalties on Russia",
              "rundown": "Biden to impose sanctions on Russia for election meddling.",
              "newsieHeadline": "Sanctions Against Russia",
              "summaryBullets": [
                  "The Biden administration is taking strong action against Russia for attempting to manipulate U.S. public opinion ahead of the election",
                  "Sanctions are being imposed on Russian individuals and entities involved in efforts to influence the U.S. election process"
              ],
              "category": "world",
              "link": "https://www.nbcnews.com/politics/2024-election/biden-administration-hit-russia-sanctions-trying-manipulate-us-opinion-rcna169541",
              "id": "Sanctions Against Russia-2024-9-4",
              "title": "Biden administration to hit Russia with sanctions for trying to manipulate U.S. opinion ahead of the election"
          }
      ],
      "politics": [
          {
              "inNewsletter": true,
              "date": "politics-2024-9-5",
              "emoji": "🕵♂",
              "clue": "Government takes control of foreign online platforms related to political interference",
              "rundown": "US confiscates Russian sites linked to 2024 election interference.",
              "newsieHeadline": "US Website Seizure",
              "summaryBullets": [
                  "US condemns Russian efforts to influence 2024 election",
                  "Justice Department seizes 32 web domains used in covert campaigns"
              ],
              "category": "politics",
              "link": "https://thehill.com/homenews/campaign/4861766-biden-administration-condemns-russian-influence/",
              "id": "US Website Seizure-2024-9-4",
              "title": "US seizes Russian websites used in bid to influence 2024 election"
          },
          {
              "date": "politics-2024-9-5",
              "emoji": "💸",
              "clue": "Campaign finances show significant deficit",
              "rundown": "Trump campaign's August spending exceeded fundraising by $32M.",
              "newsieHeadline": "Campaign Spending Deficit",
              "category": "politics",
              "link": "https://www.bloomberg.com/news/articles/2024-09-04/trump-campaign-spent-32-million-more-than-it-raised-in-august",
              "id": "Campaign Spending Deficit-2024-9-4",
              "title": "Trump Campaign Spent $32 Million More Than It Raised in August"
          },
          {
              "date": "politics-2024-9-5",
              "emoji": "🙏",
              "clue": "GOP members hope for election upset",
              "rundown": "GOP Leaders Hope for Trump's Significant Election Defeat",
              "newsieHeadline": "GOP Anti-Trump Sentiment",
              "category": "politics",
              "link": "https://www.thedailybeast.com/top-republicans-secretly-pray-for-donald-trump-to-lose-bigly?ref=wrap?ref=wrap?ref=wrap",
              "id": "GOP Anti-Trump Sentiment-2024-9-4",
              "title": "Top Republicans Secretly Pray for Trump to Lose Bigly"
          },
          {
              "date": "politics-2024-9-5",
              "emoji": "💰",
              "clue": "Potential economic impact of election",
              "rundown": "Goldman Sachs: Harris win, Democratic sweep boost economy.",
              "newsieHeadline": "Economic Boost Expected",
              "category": "politics",
              "link": "https://qz.com/kamala-harris-donald-trump-gdp-economy-goldman-sachs-1851639343",
              "id": "Economic Boost Expected-2024-9-4",
              "title": "A Kamala Harris win and a Democratic sweep would give the biggest boost to the economy, Goldman Sachs says. Harris' middle-class tax credits would outweigh the decreased investment driven by higher corporate taxes."
          },
          {
              "date": "politics-2024-9-5",
              "emoji": "🦸♂",
              "clue": "Politician criticizes lack of bravery",
              "rundown": "Kinzinger criticizes colleagues as lacking courage.",
              "newsieHeadline": "Kinzinger Calls Out",
              "category": "politics",
              "link": "https://thehill.com/homenews/campaign/4861062-adam-kinzinger-republicans-trump-cowards/",
              "id": "Kinzinger Calls Out-2024-9-4",
              "title": "Kinzinger: ‘I’m not courageous … I’m surrounded by cowards’"
          },
          {
              "date": "politics-2024-9-5",
              "emoji": "🚫",
              "clue": "Former president acknowledges electoral defeat",
              "rundown": "Trump concedes defeat in 2020 presidential election.",
              "newsieHeadline": "Trump Concedes Defeat",
              "category": "politics",
              "link": "https://www.msnbc.com/msnbc/amp-video/mmvo218571333578",
              "id": "Trump Concedes Defeat-2024-9-4",
              "title": "Trump admits he lost the 2020 presidential election"
          },
          {
              "date": "politics-2024-9-5",
              "emoji": "💔",
              "clue": "VP responds to Georgia tragedy",
              "rundown": "Harris speaks out on Georgia school shooting tragedy.",
              "newsieHeadline": "Harris Addresses Shooting",
              "category": "politics",
              "link": "https://thehill.com/homenews/administration/4861972-georgia-shooting-harris-condemns-gun-violence/",
              "id": "Harris Addresses Shooting-2024-9-4",
              "title": "Harris goes off-script to address Georgia school shooting: ‘It does not have to be this way’"
          },
          {
              "date": "politics-2024-9-5",
              "emoji": "🕵♂",
              "clue": "Influential figures accused of manipulation",
              "rundown": "Indictment claims media start-up was secret Russian campaign.",
              "newsieHeadline": "Media Influence Campaign",
              "category": "politics",
              "link": "https://www.dailydot.com/debug/russia-tenet-benny-johnson-tim-pool-indictment/",
              "id": "Media Influence Campaign-2024-9-4",
              "title": "Media start-up from Benny Johnson, Tim Pool, and Dave Rubin was secret Russian influence campaign, indictment alleges"
          },
          {
              "date": "politics-2024-9-5",
              "emoji": "🗳",
              "clue": "Republican supports Democratic candidate",
              "rundown": "Liz Cheney to vote for Harris in presidential election.",
              "newsieHeadline": "Cheney Supports Harris",
              "category": "politics",
              "link": "https://www.cnn.com/2024/09/04/politics/liz-cheney-voting-kamala-harris/index.html",
              "id": "Cheney Supports Harris-2024-9-4",
              "title": "Liz Cheney says she is voting for Harris for president | CNN Politics"
          },
          {
              "date": "politics-2024-9-5",
              "emoji": "📉",
              "clue": "Potential negative impact of election",
              "rundown": "Goldman Sachs predicts economic downturn if Trump wins.",
              "newsieHeadline": "Economic Downturn Warning",
              "category": "politics",
              "link": "https://www.rollingstone.com/politics/politics-news/goldman-sachs-economic-downturn-trump-1235094814/",
              "id": "Economic Downturn Warning-2024-9-4",
              "title": "Goldman Sachs Says Trump Win Would Lead to Economic Downturn"
          }
      ],
      "business": [
          {
              "date": "business-2024-9-5",
              "emoji": "⚠",
              "clue": "Aircraft manufacturer workers demand change",
              "rundown": "Boeing confronts strike by 32,000 workers - Fox Business",
              "newsieHeadline": "Boeing Worker Strike",
              "category": "business",
              "link": "https://www.foxbusiness.com/markets/boing-faces-strike-about-32000-workers",
              "description": "Boeing is facing yet another challenge as 32,000 members of the IAM union are threatening to strike this month if a deal isn't reached on a new contract with the company.",
              "id": "Boeing Worker Strike-2024-9-4",
              "title": "Boeing faces strike of about 32,000 workers - Fox Business"
          },
          {
              "date": "business-2024-9-5",
              "emoji": "💼",
              "clue": "Labor disputes in hotel industry",
              "rundown": "Hotel workers strike at Hilton, Hyatt, Marriott nationwide.",
              "newsieHeadline": "Hotel Workers Strike",
              "category": "business",
              "link": "https://www.usatoday.com/story/money/2024/09/03/hotel-workers-strike-hilton-hyatt-marriott-omni-unite-here/75054803007/",
              "description": "The strikes are tentatively scheduled to end Tuesday, but more could be on the way at hotels across the country.",
              "id": "Hotel Workers Strike-2024-9-4",
              "title": "'Make them pay': Thousands of Hilton, Hyatt, Marriott hotel workers on strike across US - USA TODAY"
          },
          {
              "date": "business-2024-9-5",
              "emoji": "💼",
              "clue": "Retail company going private deal",
              "rundown": "Nordstrom family and Mexican group plan $3.76B buyout.",
              "newsieHeadline": "Nordstrom Going Private",
              "category": "business",
              "link": "https://www.washingtontimes.com/news/2024/sep/4/nordstrom-family-offering-to-take-department-store/?utm_source=Twitter&amp;utm_medium=SocialFlow&amp;utm_campaign=twtnl_x",
              "id": "Nordstrom Going Private-2024-9-4",
              "title": "Nordstrom family offering to take department store private for $3.76B with Mexican retail group "
          },
          {
              "date": "business-2024-9-5",
              "emoji": "📈",
              "clue": "Company performance predictions before report",
              "rundown": "Broadcom (AVGO) pre-earnings expectations summarized by TipRanks.",
              "newsieHeadline": "Broadcom Earnings Expectations",
              "category": "business",
              "link": "https://www.tipranks.com/news/broadcom-nasdaqavgo-pre-earnings-heres-what-to-expect-2",
              "description": "Shares of semiconductor company Broadcom ($AVGO) sank in today’s trading as investors await its Q3 earnings results on September 5 after the market closes. Analysts...",
              "id": "Broadcom Earnings Expectations-2024-9-4",
              "title": "Broadcom (NASDAQ:AVGO) Pre-Earnings: Here’s What to Expect - TipRanks"
          },
          {
              "date": "business-2024-9-5",
              "emoji": "🔧",
              "clue": "Airline discovers engine issues",
              "rundown": "Cathay Pacific to replace engine parts on 15 A350s.",
              "newsieHeadline": "Cathay Engine Replacement",
              "category": "business",
              "link": "https://www.reuters.com/business/aerospace-defense/cathay-pacific-halts-flights-a350-checks-other-airlines-await-guidance-2024-09-03/",
              "description": "Hong Kong's Cathay Pacific Airways said on Tuesday it had inspected its entire Airbus A350 fleet after the in-flight failure of an engine part and found 15 aircraft with components that needed replacement.",
              "id": "Cathay Engine Replacement-2024-9-4",
              "title": "Cathay Pacific inspections find 15 A350s need engine parts replaced - Reuters.com"
          },
          {
              "inNewsletter": true,
              "date": "business-2024-9-5",
              "emoji": "💰",
              "clue": "Big lottery prize grows",
              "rundown": "September 3 Mega Millions drawing: Jackpot hits $681M.",
              "newsieHeadline": "Mega Millions Jackpot",
              "summaryBullets": [
                  "Mega Millions jackpot climbs to a whopping $681 million after Tuesday's drawing",
                  "Two people have already won Mega Millions jackpots this year, with the jackpot now at $740 million for Friday's drawing"
              ],
              "category": "business",
              "link": "https://www.usatoday.com/story/money/lottery/2024/09/03/mega-millions-winning-numbers/75064778007/",
              "description": "Mega Millions players, the results are in for Tuesday, Sept. 3. Here are the winning numbers for the drawing.",
              "id": "Mega Millions Jackpot-2024-9-4",
              "title": "Mega Millions winning numbers for September 3 drawing; Jackpot climbs to $681 million - USA TODAY"
          },
          {
              "date": "business-2024-9-5",
              "emoji": "🔌",
              "clue": "Electric vehicle with fast charging",
              "rundown": "Hyundai's Ioniq 5 becomes first EV with Tesla Supercharging.",
              "newsieHeadline": "Hyundai Ioniq 5",
              "category": "business",
              "link": "https://www.theverge.com/2024/9/3/24235272/hyundai-2025-ioniq-5-tesla-nacs-ev-charging-supercharger",
              "description": "Hyundai’s new 2025 Ioniq 5 EV has a Tesla NACS port included for Supercharging. It also has improved electric range and a new ruggedized XRT model.",
              "id": "Hyundai Ioniq 5-2024-9-4",
              "title": "Hyundai’s first EV with native Tesla Supercharging is the new Ioniq 5 - The Verge"
          },
          {
              "date": "business-2024-9-5",
              "emoji": "📉",
              "clue": "Market decline hits recent low",
              "rundown": "Stock markets experience worst day since August meltdown.",
              "newsieHeadline": "Stock Market Meltdown",
              "category": "business",
              "link": "https://www.washingtonpost.com/business/2024/09/03/stock-market-losses-tuesday/",
              "description": "The Dow Jones Industrial Average falls more than 600 points, a 1.5 percent drop for the day.",
              "id": "Stock Market Meltdown-2024-9-4",
              "title": "Stock markets suffer worst day since early August meltdown - The Washington Post"
          },
          {
              "date": "business-2024-9-5",
              "emoji": "💥",
              "clue": "Satellite TV company resists Disney",
              "rundown": "DirecTV committed to prolonged battle against Disney - NBC Sports",
              "newsieHeadline": "DirecTV vs Disney",
              "category": "business",
              "link": "https://www.nbcsports.com/nfl/profootballtalk/rumor-mill/news/directv-vows-to-fight-disney-as-long-as-it-needs-to",
              "description": "Last year, Disney's fight with Charter Communications lasted until the day of the first Monday Night Football game of the year.",
              "id": "DirecTV vs Disney-2024-9-4",
              "title": "DirecTV vows to fight Disney \"as long as it needs to\" - NBC Sports"
          },
          {
              "date": "business-2024-9-5",
              "emoji": "📉",
              "clue": "Cryptocurrency value may decrease further",
              "rundown": "Analysts: Bitcoin May Drop Despite Fed Rate Cuts.",
              "newsieHeadline": "Bitcoin Downward Trend",
              "category": "business",
              "link": "https://decrypt.co/247757/bitcoin-could-go-lower-despite-fed-rate-cuts-analysts",
              "description": null,
              "id": "Bitcoin Downward Trend-2024-9-4",
              "title": "Bitcoin Could Go Lower Despite Fed Rate Cuts, Analysts Say - Decrypt"
          }
      ],
      "world": [
          {
              "date": "world-2024-9-5",
              "emoji": "🎈",
              "clue": "Country deploys aerial devices for surveillance",
              "rundown": "Ukraine deploys balloons to spot Russian drone operators.",
              "newsieHeadline": "Ukraine Balloon Surveillance",
              "category": "world",
              "link": "https://www.kyivpost.com/post/38441",
              "id": "Ukraine Balloon Surveillance-2024-9-4",
              "title": "Ukraine uses balloons to detect Russian drone operators "
          },
          {
              "date": "world-2024-9-5",
              "emoji": "🚫",
              "clue": "Country considers prohibiting organizations presence",
              "rundown": "Swiss government seeks to ban Hamas for five years.",
              "newsieHeadline": "Hamas Ban Proposal",
              "category": "world",
              "link": "https://www.swissinfo.ch/eng/swiss-politics/the-federal-council-wants-to-ban-hamas-from-switzerland-for-5-years/87491275?utm_source=multiple&amp;utm_medium=website&amp;utm_campaign=news_en&amp;utm_content=o&amp;utm_term=wpblock_highlighted-compact-news-carousel",
              "id": "Hamas Ban Proposal-2024-9-4",
              "title": "Swiss government wants to ban Hamas from Switzerland for five years"
          },
          {
              "date": "world-2024-9-5",
              "emoji": "💼",
              "clue": "Increase labor effort and duration",
              "rundown": "Deutsche Bank CEO urges Germans to work longer, harder.",
              "newsieHeadline": "Work Harder Longer",
              "category": "world",
              "link": "https://www.politico.eu/article/deutsche-bank-ceo-christian-sewing-germany-working-hours-economy/",
              "id": "Work Harder Longer-2024-9-4",
              "title": "Deutsche Bank chief tells Germans: Work longer and harder"
          },
          {
              "date": "world-2024-9-5",
              "emoji": "🕵♂",
              "clue": "Russian employees fund US videos",
              "rundown": "Two Russia Today employees indicted for funding US company.",
              "newsieHeadline": "Russian Media Interference",
              "category": "world",
              "link": "https://www.justice.gov/usao-sdny/pr/two-rt-employees-indicted-covertly-funding-and-directing-us-company-published",
              "id": "Russian Media Interference-2024-9-4",
              "title": "Two RUSSIA TODAY Employees Indicted For Covertly Funding And Directing U.S. Company That Published Thousands Of Videos In Furtherance Of Russian Interests | United States Department of Justice"
          },
          {
              "date": "world-2024-9-5",
              "emoji": "🇦🇺",
              "clue": "Diplomatic tension over social media",
              "rundown": "Australia expresses worry over Iran's LGBTIQ+ post reaction.",
              "newsieHeadline": "Australia Concerned Iran",
              "category": "world",
              "link": "https://www.sbs.com.au/news/article/australia-very-concerned-over-irans-summoning-of-ambassador-over-pro-lgbtiq-post/ghhicfsxo",
              "id": "Australia Concerned Iran-2024-9-4",
              "title": "Australia 'very concerned' over Iran's summoning of ambassador over pro-LGBTIQ+ post"
          },
          {
              "date": "world-2024-9-5",
              "emoji": "🕵♂",
              "clue": "Foreign influence risks through attraction",
              "rundown": "China cautions students about spying risks from attractive people.",
              "newsieHeadline": "Spying Warning China",
              "category": "world",
              "link": "https://www.yahoo.com/news/china-warns-students-beautiful-women-100047363.html?guccounter=1&amp;guce_referrer=aHR0cHM6Ly93d3cuZ29vZ2xlLmNvbS8&amp;guce_referrer_sig=AQAAAEWmZTntlhhpO72fgVSe4jpa8jcAMmx55A8GtfYZNuifsD4jkK5UGmSuMAtO77nFyEXArDt9fDdG5Hye2-JfEF2PXYwC6EST1lPUScrAqeHVbO8A2VIrLXq-8uJ04KSV-ESJbr7rdkXQnIYX6SDDQgsjz0Chz0oHGJmr_o-mT_Qi",
              "id": "Spying Warning China-2024-9-4",
              "title": "China warns students 'beautiful women, handsome guys' could lure them into spying"
          },
          {
              "date": "world-2024-9-5",
              "emoji": "🤝",
              "clue": "Country seeks new international alliance",
              "rundown": "Turkey seeks to join Russia's G7 rival in NATO.",
              "newsieHeadline": "Turkey Applies G7",
              "category": "world",
              "link": "https://www.telegraph.co.uk/world-news/2024/09/04/turkey-nato-russia-g7-brics-rival-china-us/",
              "id": "Turkey Applies G7-2024-9-4",
              "title": "Turkey applies to be first Nato member to join Russia’s G7 rival"
          },
          {
              "date": "world-2024-9-5",
              "emoji": "🕵♂",
              "clue": "Russian leaders undisclosed offspring revealed",
              "rundown": "Putin's two secret sons with Alina Kabaeva confirmed.",
              "newsieHeadline": "Putin's Secret Sons",
              "category": "world",
              "link": "https://english.nv.ua/nation/journalists-reveal-putin-s-secret-sons-with-alina-kabaeva-born-in-2015-and-2019-50448250.html",
              "id": "Putin's Secret Sons-2024-9-4",
              "title": "Journalists confirm Putin has two secret sons with Alina Kabaeva"
          },
          {
              "inNewsletter": true,
              "date": "world-2024-9-5",
              "emoji": "🇷🇺",
              "clue": "US imposes penalties on Russia",
              "rundown": "Biden to impose sanctions on Russia for election meddling.",
              "newsieHeadline": "Sanctions Against Russia",
              "summaryBullets": [
                  "The Biden administration is taking strong action against Russia for attempting to manipulate U.S. public opinion ahead of the election",
                  "Sanctions are being imposed on Russian individuals and entities involved in efforts to influence the U.S. election process"
              ],
              "category": "world",
              "link": "https://www.nbcnews.com/politics/2024-election/biden-administration-hit-russia-sanctions-trying-manipulate-us-opinion-rcna169541",
              "id": "Sanctions Against Russia-2024-9-4",
              "title": "Biden administration to hit Russia with sanctions for trying to manipulate U.S. opinion ahead of the election"
          },
          {
              "date": "world-2024-9-5",
              "emoji": "🏳🌈",
              "clue": "Forced involvement of Chechen individuals",
              "rundown": "Rights group: Gay Chechen prisoners forced to fight in Ukraine.",
              "newsieHeadline": "Chechen Prisoners Coerced",
              "category": "world",
              "link": "https://tvpworld.com/82133177/gay-chechens-forced-to-fight-in-ukraine-say-rights-activists",
              "id": "Chechen Prisoners Coerced-2024-9-4",
              "title": "Gay Chechen prisoners coerced into fighting in Ukraine, says rights group"
          }
      ],
      "sports": [
          {
              "date": "sports-2024-9-5",
              "emoji": "💰",
              "clue": "NFL team signs player contract",
              "rundown": "Broncos and Patrick Surtain reach $96M extension deal.",
              "newsieHeadline": "Broncos Extend Surtain",
              "category": "sports",
              "link": "https://thescore.com/nfl/news/3067412",
              "id": "Broncos Extend Surtain-2024-9-4",
              "title": "Report: Broncos, Patrick Surtain agree to 4-year, $96M extension"
          },
          {
              "inNewsletter": true,
              "date": "sports-2024-9-5",
              "emoji": "💨",
              "clue": "Pitcher achieves recordbreaking fastball speed",
              "rundown": "Angels rookie Ben Joyce sets record with 105.5 mph pitch.",
              "newsieHeadline": "Record Breaking Pitch",
              "summaryBullets": [],
              "category": "sports",
              "link": "https://v.redd.it/11qzvwtd8tmd1",
              "id": "Record Breaking Pitch-2024-9-4",
              "title": "Angels rookie Ben Joyce throws fastest recorded strikeout pitch, 105.5 mph, in pitch-tracking era"
          },
          {
              "date": "sports-2024-9-5",
              "emoji": "🎾",
              "clue": "Underdog defeats tennis champion unexpectedly",
              "rundown": "Pegula defeats Swiatek to reach first Grand Slam semifinal.",
              "newsieHeadline": "Pegula Upsets Swiatek",
              "category": "sports",
              "link": "https://thescore.com/wta/news/3067878",
              "id": "Pegula Upsets Swiatek-2024-9-4",
              "title": "Pegula ousts top-ranked Swiatek to reach 1st Grand Slam semifinal"
          },
          {
              "date": "sports-2024-9-5",
              "emoji": "🔥",
              "clue": "Basketball team staff changes abruptly",
              "rundown": "Penny Hardaway dismisses 4 Memphis coaching staff members.",
              "newsieHeadline": "Hardaway Fires Coaches",
              "category": "sports",
              "link": "https://thescore.com/ncaab/news/3067560",
              "id": "Hardaway Fires Coaches-2024-9-4",
              "title": "Penny Hardaway fires 4 members of Memphis' coaching staff"
          },
          {
              "date": "sports-2024-9-5",
              "emoji": "",
              "clue": "Basketball player injury evaluation delay",
              "rundown": "Grizzlies' GG Jackson II to be re-evaluated in 3 months post foot surgery.",
              "newsieHeadline": "Grizzlies Player Injured",
              "category": "sports",
              "link": "https://apnews.com/article/grizzlies-gg-jackson-surgery-efab035cb92d8931c81a5cbc8e8a8f63",
              "id": "Grizzlies Player Injured-2024-9-4",
              "title": "Grizzlies forward GG Jackson II will be re-evaluated in 3 months after surgery on broken foot"
          },
          {
              "date": "sports-2024-9-5",
              "emoji": "🏎",
              "clue": "Car racing team considers Indy comeback",
              "rundown": "Larson, Hendrick Motorsports mull 2025 Indy 500 comeback.",
              "newsieHeadline": "Larson Indy Return",
              "category": "sports",
              "link": "https://apnews.com/article/nascar-indycar-kyle-larson-indianapolis-500-hendrick-b3418a542a6af0c0b05f5a0393ce395b",
              "id": "Larson Indy Return-2024-9-4",
              "title": "Larson and Hendrick Motorsports in discussion on a 2025 return to Indianapolis 500"
          },
          {
              "date": "sports-2024-9-5",
              "emoji": "💥",
              "clue": "Baseball player injured during game",
              "rundown": "Bryce Harper hit on elbow, exits game.",
              "newsieHeadline": "Harper Injured Elbow",
              "category": "sports",
              "link": "https://www.espn.com/mlb/story/_/id/41124957/phillies-bryce-harper-hit-left-elbow-pitch-leaves-game",
              "id": "Harper Injured Elbow-2024-9-4",
              "title": "Phillies' Bryce Harper hit on left elbow by pitch, leaves game"
          },
          {
              "date": "sports-2024-9-5",
              "emoji": "🚫",
              "clue": "Top football award nominees change",
              "rundown": "Ballon d'Or shortlist excludes Messi and Ronaldo after 21 years.",
              "newsieHeadline": "No Messi Ronaldo",
              "category": "sports",
              "link": "https://twitter.com/brfootball/status/1831409245319000322?t=cNLvXNZ3DS4NCywkmTmvoA&amp;s=19",
              "id": "No Messi Ronaldo-2024-9-4",
              "title": "For the first time in 21 years, the Ballon d'Or shortlist does not include Messi or Ronaldo"
          },
          {
              "date": "sports-2024-9-5",
              "emoji": "🏀",
              "clue": "Young basketball player achieves historic award",
              "rundown": "Caitlin Clark makes history as WNBA Rookie of the Month.",
              "newsieHeadline": "Historic Rookie Achievement",
              "category": "sports",
              "link": "https://www.cbssports.com/wnba/news/caitlin-clark-becomes-first-rookie-to-earn-player-of-the-month-honors-in-wnba-history/",
              "id": "Historic Rookie Achievement-2024-9-4",
              "title": "Caitlin Clark becomes first rookie to earn Player of the Month honors in WNBA history"
          },
          {
              "date": "sports-2024-9-5",
              "emoji": "🚫",
              "clue": "Historic nohit game achievement",
              "rundown": "Cubs' Imanaga, Pearson, Hodge pitch first home no-hitter in 52 years.",
              "newsieHeadline": "Cubs Home No-Hitter",
              "category": "sports",
              "link": "https://thescore.com/mlb/news/3067892",
              "id": "Cubs Home No-Hitter-2024-9-4",
              "title": "Chicago Cubs pitchers Shota Imanaga, Nate Pearson, and Porter Hodge combined to no-hit the Pittsburgh Pirates. First home no-hitter in 52 years"
          }
      ],
      "entertainment": [
          {
              "date": "entertainment-2024-9-5",
              "emoji": "🎶",
              "clue": "Singer supports controversial music producer",
              "rundown": "Katy Perry stands by working with Dr. Luke.",
              "newsieHeadline": "Katy Perry Supports",
              "category": "entertainment",
              "link": "https://deadline.com/2024/09/katy-perry-defends-collaboration-dr-luke-143-call-her-daddy-1236077406/",
              "id": "Katy Perry Supports-2024-9-4",
              "title": "Katy Perry Defends Collaboration With Dr. Luke On New Album '143'"
          },
          {
              "inNewsletter": true,
              "date": "entertainment-2024-9-5",
              "emoji": "🕵♂",
              "clue": "Legal process against Hollywood figure",
              "rundown": "New evidence against Harvey Weinstein reviewed by NY jury.",
              "newsieHeadline": "Weinstein New Evidence",
              "summaryBullets": [
                  "New evidence against Harvey Weinstein being considered by grand jury",
                  "Weinstein's attorney ready for potential new charges"
              ],
              "category": "entertainment",
              "link": "https://www.nbcnews.com/news/us-news/new-york-grand-jury-weighing-new-evidence-harvey-weinstein-source-says-rcna169550",
              "id": "Weinstein New Evidence-2024-9-4",
              "title": "A New York grand jury is weighing new evidence against Harvey Weinstein, a source says "
          },
          {
              "date": "entertainment-2024-9-5",
              "emoji": "🦹♀",
              "clue": "Actress cast in iconic role",
              "rundown": "Alison Brie cast as Evil-Lyn in 'Masters of the Universe'.",
              "newsieHeadline": "Alison Brie Casting",
              "category": "entertainment",
              "link": "https://deadline.com/2024/09/masters-of-the-universe-alison-brie-to-play-evil-lyn-amazon-mgm-1236078193/",
              "id": "Alison Brie Casting-2024-9-4",
              "title": "‘Masters of the Universe’ Taps Alison Brie To Play Villain Evil-Lyn, Joining Nicholas Galitzine as He-Man and Camila Mendes as Teela"
          },
          {
              "date": "entertainment-2024-9-5",
              "emoji": "🤣",
              "clue": "Late night host mocks president",
              "rundown": "Colbert mocks Trump with scathing definition of word.",
              "newsieHeadline": "Colbert Mocks Trump",
              "category": "entertainment",
              "link": "https://www.huffpost.com/entry/stephen-colbert-trump-speech_n_66d7f20ce4b0688475d8e199",
              "id": "Colbert Mocks Trump-2024-9-4",
              "title": "Stephen Colbert Taunts 'Demented' Trump With Scathing Definition For His Made-Up Word"
          },
          {
              "date": "entertainment-2024-9-5",
              "emoji": "💔",
              "clue": "Actor values past relationship bond",
              "rundown": "Justin Theroux remains protective of ex Jennifer Aniston.",
              "newsieHeadline": "Justin's Protective Love",
              "category": "entertainment",
              "link": "https://ew.com/newly-engaged-justin-theroux-says-hes-still-protective-of-ex-wife-jennifer-aniston-8706038",
              "id": "Justin's Protective Love-2024-9-4",
              "title": "Newly engaged Justin Theroux says he's still protective of ex-wife Jennifer Aniston: 'She is still very dear to me'"
          },
          {
              "date": "entertainment-2024-9-5",
              "emoji": "🤔",
              "clue": "TV show critique podcast launch",
              "rundown": "Emily Deschanel acknowledges Bones flaws in rewatch podcast.",
              "newsieHeadline": "Bones Podcast Critique",
              "category": "entertainment",
              "link": "https://people.com/emily-deschanel-admits-some-aspects-of-bones-didnt-age-well-rewatch-podcast-exclusive-8705885",
              "id": "Bones Podcast Critique-2024-9-4",
              "title": "Emily Deschanel Admits Some Aspects of Bones Didn't Age Well as She Releases Rewatch Podcast with Carla Gallo"
          },
          {
              "date": "entertainment-2024-9-5",
              "emoji": "🎮",
              "clue": "Video game movies in 2024",
              "rundown": "Minecraft movie trailer released, Borderlands film may surprise in 2024.",
              "newsieHeadline": "Minecraft Movie Trailer",
              "category": "entertainment",
              "link": "https://www.pcgamer.com/movies-tv/the-first-minecraft-movie-trailer-is-here-and-now-im-thinking-the-borderlands-film-might-not-be-the-worst-thing-to-happen-to-cinema-in-2024/",
              "id": "Minecraft Movie Trailer-2024-9-4",
              "title": "The first Minecraft movie trailer is here, and now I'm thinking the Borderlands film might not be the worst thing to happen to cinema in 2024"
          },
          {
              "date": "entertainment-2024-9-5",
              "emoji": "🤐",
              "clue": "Actor stays silent after departure",
              "rundown": "Joaquin Phoenix Silent on Departure from Todd Haynes Film",
              "newsieHeadline": "Phoenix Silent Exit",
              "category": "entertainment",
              "link": "https://people.com/joaquin-phoenix-declines-to-address-exit-todd-haynes-gay-romance-movie-venice-8697789",
              "id": "Phoenix Silent Exit-2024-9-4",
              "title": "Joaquin Phoenix Declines to Address Sudden Exit from Todd Haynes' Gay Romance: Speaking Out Wouldn't 'Be Right'"
          },
          {
              "date": "entertainment-2024-9-5",
              "emoji": "🏰",
              "clue": "Theme park club lawsuit loss",
              "rundown": "Couple's bid to re-enter Club 33 denied in court.",
              "newsieHeadline": "Club 33 Lawsuit",
              "category": "entertainment",
              "link": "https://www.latimes.com/california/story/2024-09-04/disney-obsessed-couple-loses-lawsuit-to-get-back-into-exclusive-club-33",
              "id": "Club 33 Lawsuit-2024-9-4",
              "title": "Disney-obsessed couple loses lawsuit to get back into exclusive Club 33"
          }
      ],
      "science": [
          {
              "date": "science-2024-9-5",
              "emoji": "💰",
              "clue": "Global crisis due to aging",
              "rundown": "Global policy action needed as Alzheimer's crisis looms large.",
              "newsieHeadline": "Economic Crisis Looms",
              "category": "science",
              "link": "https://www.thelancet.com/journals/langlo/article/PIIS2214-109X(24)00264-X/fulltext",
              "id": "Economic Crisis Looms-2024-9-4",
              "title": "As the world's population ages, Alzheimer's and dementia are set to create a staggering $14.5 trillion economic crisis, with informal caregiving placing an overwhelming burden on both high and low-income countries, demanding urgent global policy action"
          },
          {
              "date": "science-2024-9-5",
              "emoji": "🦕",
              "clue": "Ancient European dinosaur discovery revealed",
              "rundown": "Qunkasaura: Europe's most complete sauropod fossil discovery.",
              "newsieHeadline": "Dinosaur Discovery Spain",
              "category": "science",
              "link": "https://www.eurekalert.org/news-releases/1056565",
              "id": "Dinosaur Discovery Spain-2024-9-4",
              "title": "Spain’s long-necked dinosaur star emerges from a 12,000-fossil find | Qunkasaura is notable for being among the most complete sauropod skeletons ever unearthed in Europe."
          },
          {
              "inNewsletter": true,
              "date": "science-2024-9-5",
              "emoji": "🧠",
              "clue": "Toxic chemicals impact brain development",
              "rundown": "PFAS chemicals linked to various health issues, brain development.",
              "newsieHeadline": "PFAS Brain Effects",
              "summaryBullets": [],
              "category": "science",
              "link": "https://www.ufz.de/index.php?en=36336&amp;webc_pm=33/2024",
              "id": "PFAS Brain Effects-2024-9-4",
              "title": "PFAS “forever chemicals” can lead to liver damage, obesity, hormonal disorders, and cancer, and a new study finds they also influence the development and function of the brain. A new study reveals mechanism of action and genes involved using a zebrafish model. These genes are also present in humans."
          },
          {
              "date": "science-2024-9-5",
              "emoji": "🚀",
              "clue": "Quantum computing advances with precision",
              "rundown": "Quantum processor achieves 99.9% qubit fidelity milestone.",
              "newsieHeadline": "Quantum Computing Breakthrough",
              "category": "science",
              "link": "https://interestingengineering.com/science/iqm-quantum-computers-record-low-error",
              "id": "Quantum Computing Breakthrough-2024-9-4",
              "title": "Prototype quantum processor hits record 99.9% qubit fidelity in a major milestone | The achievement marks a way toward “fault-tolerant” quantum computing."
          },
          {
              "date": "science-2024-9-5",
              "emoji": "🔬",
              "clue": "Brain disease detection breakthrough discovered",
              "rundown": "New Biomarker Shows Promise for Early Alzheimer's Diagnosis",
              "newsieHeadline": "Alzheimer's Biomarker Discovery",
              "category": "science",
              "link": "https://www.eurekalert.org/news-releases/1056846",
              "id": "Alzheimer's Biomarker Discovery-2024-9-4",
              "title": "Novel biomarker could lead to early diagnosis of Alzheimer’s disease, study finds: Relative levels of potassium isotopes in human blood serum shows potential to diagnose early onset of Alzheimer's disease"
          },
          {
              "date": "science-2024-9-5",
              "emoji": "💪",
              "clue": "Persistent illness affects lungs recovery",
              "rundown": "Targeting overactive CD8 T-cells in mice reverses lung damage from Long COVID.",
              "newsieHeadline": "Lung Repair Potential",
              "category": "science",
              "link": "https://theconversation.com/long-covid-inflicts-deep-scars-on-the-lungs-but-targeting-specific-immune-cells-could-reverse-damage-new-research-in-mice-237925",
              "id": "Lung Repair Potential-2024-9-4",
              "title": "Long COVID inflicts deep scars on the lungs but targeting overactive CD8 T-cells in the immune system of mice shows promise in reversing damage "
          },
          {
              "date": "science-2024-9-5",
              "emoji": "",
              "clue": "Rapid DNA analysis innovation introduced",
              "rundown": "New forensic tech reduces sexual assault DNA test time.",
              "newsieHeadline": "Rapid DNA Analysis",
              "category": "science",
              "link": "https://onlinelibrary.wiley.com/doi/10.1002/advs.202405712",
              "id": "Rapid DNA Analysis-2024-9-4",
              "title": "No lab needed: New forensic tech cuts sexual assault DNA test time to 45 mins | The researchers have introduced a novel method for separating two individuals’ DNA using a differential digestion technique combined with digital microfluidics."
          },
          {
              "date": "science-2024-9-5",
              "emoji": "🐶",
              "clue": "Pets recall toy names",
              "rundown": "Dogs remember toy names long-term, study finds.",
              "newsieHeadline": "Canine Memory Recall",
              "category": "science",
              "link": "https://www.theguardian.com/science/article/2024/sep/04/dogs-remember-names-toys-years-study-pets-memory",
              "id": "Canine Memory Recall-2024-9-4",
              "title": "Dogs can remember names of toys years after not seeing them, new study shows. Research into canine cognition suggests some pets store object names in long-term memory. "
          },
          {
              "date": "science-2024-9-5",
              "emoji": "🧠",
              "clue": "Brain training improves emotional control",
              "rundown": "Study shows neurofeedback training improves emotional skills in rehab.",
              "newsieHeadline": "Emotional Skills Enhanced",
              "category": "science",
              "link": "https://www.psypost.org/neurofeedback-training-enhances-emotional-skills-in-alcohol-rehabilitation-study-finds/#google_vignette",
              "id": "Emotional Skills Enhanced-2024-9-4",
              "title": "Neurofeedback training enhances emotional skills in alcohol rehabilitation, study finds"
          }
      ],
      "uplifting": [
          {
              "date": "uplifting-2024-9-5",
              "emoji": "🌊",
              "clue": "Exploration of vast underwater peak",
              "rundown": "Exploration of vast underwater mountain reveals incredible wonders.",
              "newsieHeadline": "Underwater Mountain Exploration",
              "category": "uplifting",
              "link": "https://www.goodnewsnetwork.org/oceanographers-explore-underwater-mountain-bigger-than-mount-olympus-teeming-with-wonders/",
              "id": "Underwater Mountain Exploration-2024-9-4",
              "title": "Oceanographers Explore Underwater Mountain Bigger Than Mount Olympus Teeming with Wonders"
          },
          {
              "date": "uplifting-2024-9-5",
              "emoji": "🌱",
              "clue": "Toy company using sustainable materials",
              "rundown": "LEGO aims for 50% renewable plastic in bricks by 2026.",
              "newsieHeadline": "LEGO Sustainable Materials",
              "category": "uplifting",
              "link": "https://www.goodnewsnetwork.org/lego-paying-out-of-pocket-to-make-half-the-plastic-in-its-bricks-from-renewable-materials-by-2026/",
              "id": "LEGO Sustainable Materials-2024-9-4",
              "title": "LEGO Paying Out of Pocket to Make Half the Plastic in Its Bricks from Renewable Materials by 2026"
          },
          {
              "date": "uplifting-2024-9-5",
              "emoji": "🧪",
              "clue": "New filter removes harmful substance",
              "rundown": "New filter removes PFAS, used in renewable batteries.",
              "newsieHeadline": "PFAS Filter Invention",
              "category": "uplifting",
              "link": "https://www.abc.net.au/news/2024-09-05/scientists-invent-pfas-filter-for-battery-technology/104308214",
              "id": "PFAS Filter Invention-2024-9-4",
              "title": "Scientists invent filter to remove 'forever chemical' PFAS and use it in renewable batteries"
          },
          {
              "date": "uplifting-2024-9-5",
              "emoji": "🍇",
              "clue": "Winethemed feature at airport expansion",
              "rundown": "Tuscany airport terminal to feature rooftop vineyard.",
              "newsieHeadline": "Tuscany's Vineyard Terminal",
              "category": "uplifting",
              "link": "https://www.goodnewsnetwork.org/tuscanys-new-airport-terminal-will-have-a-vineyard-on-the-roof-obviously/",
              "id": "Tuscany's Vineyard Terminal-2024-9-4",
              "title": "Tuscany’s New Airport Terminal Will Have a Vineyard on the Roof, Obviously"
          },
          {
              "date": "uplifting-2024-9-5",
              "emoji": "🎉",
              "clue": "Health insurance expands fertility coverage",
              "rundown": "Aetna to cover IUI post lawsuit, joy for all.",
              "newsieHeadline": "Aetna Covers IUI",
              "category": "uplifting",
              "link": "https://www.lgbtqnation.com/2024/09/aetna-to-start-covering-iui-after-a-lawsuit-providers-and-families-rejoice/",
              "id": "Aetna Covers IUI-2024-9-4",
              "title": "Aetna to start covering IUI after a lawsuit, providers and families rejoice"
          },
          {
              "date": "uplifting-2024-9-5",
              "emoji": "🐴",
              "clue": "Equine therapy aids Tennessee veterans",
              "rundown": "Tennessee veterans use horses for healing with biofeedback.",
              "newsieHeadline": "Veterans Healing Horses",
              "category": "uplifting",
              "link": "https://www.goodnewsnetwork.org/tennessee-veterans-find-healing-working-with-bio-feedback-machine-horses/",
              "id": "Veterans Healing Horses-2024-9-4",
              "title": "Tennessee Veterans Find Healing Working with ‘Bio-feedback machine’ Horses"
          },
          {
              "inNewsletter": true,
              "date": "uplifting-2024-9-5",
              "emoji": "💉",
              "clue": "Antibody shields against diverse viruses",
              "rundown": "Antibody found to guard against all COVID variants.",
              "newsieHeadline": "COVID Variant Protection",
              "summaryBullets": [],
              "category": "uplifting",
              "link": "https://www.cell.com/cell-reports-medicine/fulltext/S2666-3791(24)00382-3",
              "id": "COVID Variant Protection-2024-9-4",
              "title": "Newly discovered antibody protects against all known COVID variants"
          },
          {
              "date": "uplifting-2024-9-5",
              "emoji": "💊",
              "clue": "Dad invents lifesaving treatment widely sought",
              "rundown": "Dad's rare disease drug in high demand by families.",
              "newsieHeadline": "Life-Saving Drug Desperation",
              "category": "uplifting",
              "link": "https://www.foxnews.com/health/father-created-drug-save-his-son-from-rare-disease-now-other-families-desperate-get",
              "id": "Life-Saving Drug Desperation-2024-9-4",
              "title": "Father created a drug to save his son from a rare disease, now other families are desperate to get it"
          },
          {
              "date": "uplifting-2024-9-5",
              "emoji": "🐺",
              "clue": "Canines returning to California wilderness",
              "rundown": "Historic resurgence of gray wolves in California.",
              "newsieHeadline": "Wolf Revival California",
              "category": "uplifting",
              "link": "https://www.ktvu.com/news/gray-wolves-making-historic-comeback-california",
              "id": "Wolf Revival California-2024-9-4",
              "title": "Gray wolves making historic comeback in California"
          },
          {
              "date": "uplifting-2024-9-5",
              "emoji": "🚗",
              "clue": "Charity aids mom kids safety",
              "rundown": "Charity aids mother of 4 in obtaining car seats.",
              "newsieHeadline": "Car Seat Assistance",
              "category": "uplifting",
              "link": "https://news4sanantonio.com/news/local/non-profit-helps-mother-of-4-secure-car-seats-after-tragic-accident-rocks-community-san-antonio-texas-investigation-eagles-flight-kids-children",
              "id": "Car Seat Assistance-2024-9-4",
              "title": "Non-profit helps mother of 4 secure car seats after tragic accident rocks community"
          }
      ]
  }
}

export default stories;
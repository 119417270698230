import React, { useState, useEffect } from "react";

import "./IncorrectGuesses.css";

const Stopwatch = (props) => {
  const { incorrectGuesses } = props;
  const offset = 255 - incorrectGuesses * 25;
  return (
    <div className="incorrect-guesses-container">
      <div className="incorrect-guesses-heading">Incorrect</div>
      <div
        className="incorrect-guesses-square"
        style={{ backgroundColor: `rgb(255,${offset},${offset})` }}
      >
        <div
        style={{ marginTop: "-20%" }}
        >
          {incorrectGuesses}
          </div>
      </div>
    </div>
  );
};

export default Stopwatch;

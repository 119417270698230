import React from "react";

import {useSelector, useDispatch} from 'react-redux';
import { dismissModal } from "../../../../redux/features/modalSlice";
import UserInfo from "../HamburgerMenu/pages/Profile/UserInfo";

import "./UpdateUsername.css";

const UpdateUsername = () => {
  const dispatch = useDispatch();
  const username = useSelector((state) => state.profileReducer.username);

  return (
    <div className='update-username-modal' onClick={(e)=> e.stopPropagation()}>
      <div>
        <h4 style={{margin: '0px'}}>We've assigned you a username!</h4>
        <h3> Welcome to Newsie: {username}</h3>
      </div>
      <div style={{ marginLeft: "5%", marginRight: "5%" }}>
        If you want, you can pick a different one:
      </div>
      <UserInfo usernameInputLabel="" />
      <div>
        or you can always change it later.
      </div>
      <div className='update-username-modal-continue-button' 
      onClick={() => dispatch(dismissModal())}
      >
        Continue
      </div>
    </div>
  );
};

export default UpdateUsername;

import React, {
  useCallback,
  useEffect,
  useMemo,
  useState,
  useRef,
} from "react";

import {
  setAuthEmail,
  setLoginModalScreen,
  // submitTwitteroAuthToken,
  authSuccess,
  setCaptchaAction,
} from "../../../../../../redux/features/authSlice";

import { useDispatch, useSelector } from "react-redux";
import {
  checkEmail,
  loginWithGoogle,
  // loginWithTwitter,
  // loginWithFacebook,
} from "../../api";
import {
  LOADING_SCREEN,
  DEFAULT,
  CREATE_WITH_EMAIL,
  LOGIN_WITH_EMAIL,
  CHECK_YOUR_EMAIL,
  CAPTCHA,
} from "../../index";

// import fbLogo from "../../../../../../Assets/facebook-svgrepo-com.svg";
// import twitterLogo from "../../../../../../Assets/twitter-svgrepo-com.svg";

import "./Default.css";
import { fetchProfileStart } from "../../../../../../redux/features/profileSlice";

import { LOCAL_STORAGE_KEYS } from "../../../../../../util/constants";

const BUTTON_DIV = "button-div";

function isValidEmail(email) {
  // Regular expression for a valid email address
  const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;

  // Test the email against the regex pattern
  return emailRegex.test(email);
}

const Default = (props) => {
  const [email, setEmail] = useState("");
  const [twitterWindow, setTwitterWindow] = useState(null);
  const ref = useRef(null);
  const browserId = useSelector((state) => state.authReducer.browserId);
  const userPerformance = useSelector(state => state.userPerformanceReducer);
  const isEmailValid = useMemo(() => isValidEmail(email), [email]);
  const dispatch = useDispatch();
  const handleEmailInputChanged = (e) => {
    // console.log(e.target.value);
    setEmail(e.target.value);
  };

  const handleClickContinueWithEmail = useCallback(async () => {
    dispatch(setAuthEmail(email));
    // dispatch(setLoginModalScreen(CAPTCHA));
    dispatch(setLoginModalScreen(LOADING_SCREEN));
    const res = await checkEmail(email, browserId);
    if (res.accountEmail) {
      // Existing Account
      dispatch(setLoginModalScreen(LOGIN_WITH_EMAIL));
    } else {
      // New Account
      dispatch(setCaptchaAction("EMAIL_CREATE_ACCOUNT"));
      dispatch(setLoginModalScreen(CAPTCHA));
    }
  }, [email, browserId]);

  function removeEscapedCharacters(inputString) {
    // Use a regular expression to match and replace escaped characters
    const cleanedString = inputString.replace(/\\./g, "");
    return cleanedString;
  }

  const handleAuthResult = (res) => {
    const { apiToken } = res;
    if (apiToken) {
      dispatch(authSuccess(apiToken));
      localStorage.setItem(LOCAL_STORAGE_KEYS.SESSION_TOKEN, apiToken);
      // dispatch(fetchProfileStart());
    }
  };

  const handleLoginWithGoogle = useCallback(
    async (code) => {
      dispatch(setLoginModalScreen(LOADING_SCREEN));
      const result = await loginWithGoogle(code, browserId, userPerformance);
      const payload = await result.json();
      dispatch(setLoginModalScreen(DEFAULT));
      if (payload) handleAuthResult(payload);
    },
    [browserId]
  );

  useEffect(() => {
    // eslint-disable-next-line
    google?.accounts.id.initialize({
      client_id:
        "81391574523-jp2oj7fb9p8ngo5gh1bmnrusncl056pr.apps.googleusercontent.com",
      callback: (response) => {
        const cleanCred = removeEscapedCharacters(response?.credential);
        handleLoginWithGoogle(cleanCred);
      },
    });
    // https://developers.google.com/identity/gsi/web/reference/js-reference
    // eslint-disable-next-line
    google.accounts.id.renderButton(document.getElementById(BUTTON_DIV), {
      theme: "filled_black",
      size: "large",
      width: 300,
      text: "continue_with",
      ux_mode: "redirect",
      login_uri: "https://www.newsie.fyi",
    });
  }, []);

  return (
    <>
      <div className="default-login-content">
        <div className="default-login-header">
          <h3>Log in or create an account</h3>
        </div>
        <fieldset className="email-fieldset" type="email">
          <label className="email-input-form-label" htmlFor="email">
            Email
          </label>
          <div className="email-input-flex-container">
            <input
              className="email-input"
              id="email"
              type="email"
              value={email}
              onChange={handleEmailInputChanged}
              autoComplete="email"
            />
          </div>
        </fieldset>
        <button
          className="login-button"
          disabled={!isEmailValid}
          onClick={handleClickContinueWithEmail}
        >
          <b>Continue with Email</b>
        </button>
        <div className="default-login-or">
          <h3>--- or ---</h3>
        </div>
        <div className="login-buttons-container">
          <div className="login-button-container">
            <div id={BUTTON_DIV} />
          </div>
        </div>
      </div>
    </>
  );
};

export default Default;

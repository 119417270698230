import React, { useState, useEffect } from "react";


import "./Score.css";

const Score = (props) => {
  const {score} = props;
  
  // const offset = 255 - incorrectGuesses * 25;
  return (
    <div className="score-container">
      <div className="score-heading">Score</div>
      <div className="score-guesses-score"><b>{score}</b></div>
    </div>
  );
};

export default Score;

import React from 'react';
import CorrectWord from '../CorrectWord';

import './CorrectWords.css';

const CorrectWords = (props) => {
  const {words} = props;
  return (
    <div className='story-correct-words-container'>
    {words && words.map((word, i) => <CorrectWord key={i} word={word} />)}
    </div>
  )
};

export default CorrectWords;